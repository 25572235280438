import React, { useState } from 'react';
import Welcome from './welcome';
import OneToOne from './oneToOne';
import Course from './course';
import Program from './program';


const Tutorial = ({ onClose, name }) => {

    const [display, setDisplay] = useState('welcome');

    const handleNavigate = (content) =>{
        setDisplay(content);
    }


    return (
        <>
            <Welcome 
                open={display === 'welcome'}
                name={name}
                handleNavigate={handleNavigate}
                onClose={onClose}
            />
            <OneToOne 
                open={display ==='1:1'}
                onClose={onClose}
                handleNavigate={handleNavigate}
            />
            <Course 
               open={display ==='course'}
               onClose={onClose}
               handleNavigate={handleNavigate}
            />  

            <Program 
                open={display ==='program'}
                onClose={onClose}
                handleNavigate={handleNavigate}
            />
            
        </>
    )
}

export default Tutorial;