import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'

/**
 * Update note list for patient with @param patientId 's profile
 * from current sigin practitioner.
 *
 * @param {String} patientId Patient's Firebase id
 * @param {Number} index Index of note to be removed from the notes list
 * @param {Object} newItem note item
 * @param {string} label notes
 * @return {Promise<any>}
 */
const updateNote = (patientId, index, newItem, label) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ accessToken }) => {
                const params = { index, newItem }
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                    data: { label },
                }
                axios
                    .put(
                        `${baseUrl}/patients/${patientId}/recommendations/update`,
                        params,
                        reqBody
                    )
                    .then((res) => resolve(res.data.notes[0]))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default updateNote
