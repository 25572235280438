import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { LIGHT_GRAY } from '../constants/colors'
import './loadingSpinner.css'

const useStyles = makeStyles((theme) => ({
    grid: {
        backgroundColor: "#FCFDFC",
        alignItems: 'center',
        minHeight: '100vh',
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    bottom: {
        color: LIGHT_GRAY,
    },
    top: {
        color: theme.palette.primary.main,
        animationDuration: '550ms',
        marginLeft: '-50px',
    },
    circle: {
        strokeLinecap: 'round',
        color: '#323232'
    },
    label: {
        color: '#000',
        // marginLeft: '15px',
        fontSize: '25px',
        fontFamily: "Value Serif Pro",
        textAlign: 'center'
    },
    spinner: {
        width: '200px'
    }
}))

function LoadingSpinner(props) {
    const classes = useStyles()
    const { position, top, left } = props

    return (
        <Grid
            container
            spacing={2}
            direction="column"
            justifyContent="center"
            className={classes.grid}
        >
            <div
                className={classes.root}
                style={{ position: position, top: top, left: left }}
            >
                {/* <CircularProgress
                    variant="determinate"
                    className={classes.bottom}
                    size={50}
                    thickness={4}
                    value={100}
                /> */}
                {/* <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    className={classes.top}
                    classes={{
                        circle: classes.circle,
                    }}
                    size={50}
                    thickness={4}
                /> */}
                <span class="loader"></span>
                <div variant="h6" className={classes.label}>
                    Breathe in and out and imagine mountain air...
                </div>
            </div>
        </Grid>
    )
}

export default LoadingSpinner
