import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'
import { populateIndex } from '../../../helpers/generalHelpers'

/**
 * Remove one or more recommendations from patient with @param patientId 's profile.
 *
 * @param {String} patientId Patient's Firebase id
 * @param {Number[]} indices An array of indices of recommendation to be removed from the recommedations list
 * @param {string} label recommendation
 * @return {Promise<any>} Updated recommendation list
 */
const removePatientProgram = (patientId, indices, label, syncedProgram) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ id, accessToken }) => {
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                    data: {
                        indices,
                        type: label,
                        practitionerId: id,
                        syncedProgram,
                    },
                }
                axios
                    .delete(
                        `${baseUrl}/patients/${patientId}/program/delete`,
                        reqBody
                    )
                    .then((res) => resolve('success'))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default removePatientProgram
