/**
 * Logout practitioner from Quickblox account.
 */
function qbLogout() {
    localStorage.clear() // Clear all local storage
    QB.chat.disconnect()
    QB.destroySession(() => null)
}

export default qbLogout
