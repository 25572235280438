import axios from 'axios'
import { populateIndex } from '../../../helpers/generalHelpers'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'

/**
 * Get a list of program items from Program-Practitioner table in DynamoDB.
 *
 * @return {Promise<any>}
 */
const getPractitionerPrograms = () => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ id, accessToken }) => {
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
                axios
                    .get(`${baseUrl}/practitioner/${id}/programs`, reqBody)
                    .then((res) => {
                        const modules = res.data.modules ? res.data.modules : []
                        const programs = res.data.programs
                            ? res.data.programs
                            : []
                        const draftSyncedModules = res.data.draftSyncedPrograms
                            ? res.data.draftSyncedPrograms
                            : []
                        const syncedPrograms = res.data.syncedPrograms
                            ? res.data.syncedPrograms
                            : []
                        const files = res.data.files ? res.data.files : []
                        const result = {
                            programs: populateIndex(programs),
                            modules: populateIndex(modules),
                            draftSyncedModules:
                                populateIndex(draftSyncedModules),
                            syncedPrograms: populateIndex(syncedPrograms),
                            files: files,
                        }
                        resolve(result)
                    })
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default getPractitionerPrograms
