import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'

/**
 * Replace the element at position @param index from the 'syncedPrograms' list
 * with @param newSyncedProgram for current signin practitioner.
 *
 * @param {Object} newSyncedProgram
 * @param {Object} data
 * @param {Object} newSchedule
 * @return {Promise<any>}
 */
const updateSyncedProgram = (newSyncedProgram, index) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ id, accessToken }) => {
                const params = { newSyncedProgram, index }
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }

                axios
                    .put(
                        `${baseUrl}/practitioner/${id}/program/sync/update`,
                        params,
                        reqBody
                    )
                    .then((res) => resolve(res))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default updateSyncedProgram
