/**
 * @description This file contains all general helper functions that
 *              do not fit into other helper categories.
 */

/**
 * Add index attribute to each object element within the array @param arr .
 *
 * @param {Object[]} arr
 * @return {Object[]} new object array with extra attribute 'index' indicating the element's position
 *                    in @param arr
 */
export const populateIndex = (arr) => {
    return arr.map((r, i) => {
        return { ...r, index: i }
    })
}

/**
 * Add index attribute to each package within the array @param pkgs and
 * convert price_in_cents to dollars.
 *
 * @param {Object[]} pkgs - An array of package objects
 * @return {Object[]} new package array with extra attribute 'index' indicating the element's position
 *                    in @param pkgs and 'price' in dollars
 */
export const convertPrice = (pkgs) => {
    return pkgs.map((p, i) => {
        return {
            ...p,
            index: i,
            price: p.price_in_cents / 100,
        }
    })
}

/**
 * Return an array with length @param length filled with @param value .
 *
 * @param {Number} length
 * @param {Any} value
 * @return {Any[]}
 */
export const prefillArray = (length, value) => {
    let result
        ; (result = []).length = length
    result.fill(value)
    return result
}

/**
 * Transform @param arr into a new array wih length @param arr.length/@param groupSize
 * where each group is an element of the new array and
 * each group is an array of length @param groupSize .
 *
 * @param {Any[]} arr - Array to be transformed
 * @param {Number} groupSize
 * @return {Any[]} A new array where elements are array with length @param groupSize
 */
export const groupArrayItems = (arr, groupSize) => {
    if (!arr) return []
    let newArray = []
    while (arr.length) {
        newArray.push(arr.splice(0, groupSize))
    }


    for (let i = 0; i < newArray.length; i++) {


        if (newArray[i].length !== groupSize) {
            const missingElement = groupSize - newArray[i].length
            console.log(missingElement)


            for(let x = 0; x < missingElement; x++){
                newArray[i].push({})
            }

        }

    }
    console.log(newArray)




    return newArray
}

/**
 *	Split an array into @param parts arrays.
 *
 * @param {Object} array
 * @param {Number} parts
 * @returns {Object} result
 */
export const splitToChunks = (array, parts) => {
    let result = []
    for (let i = parts; i > 0; i--) {
        result.push(array.splice(0, Math.ceil(array.length / i)))
    }
    return result
}

/**
 *	Split an array into arrays each with length specific @param size.
 *
 * @param {Object} arr
 * @param {Number} size
 * @returns {Object} result
 */

export const splitToEqualChunks = (arr, size) => arr.reduce((acc, e, i) => (i % size ? acc[acc.length - 1].push(e) : acc.push([e]), acc), []);


/**
 *
 * @param {Object} list array
 * @param {Mixed} values
 * 									a) array of string values
 * 									b) string value
 * @param {Mixed} props
 * 									a) array of string props
 * 									b) string prop
 * @returns {Bool}
 */
export const objectPropInArray = (list, values, props) => {
    if (list.length > 0) {
        for (let i in list) {
            if (
                values.length === 2 &&
                props.length === 2 &&
                list[i][props[0]] === values[0] &&
                list[i][props[1]] === values[1]
            ) {
                return true
            }
            if (list[i][props] === values) {
                return true
            }
        }
    }
    return false
}
