import React , { useState } from 'react'
import { 
    Grid, 
    Box, 
    makeStyles, 
    Button, 
    Typography, 
    Dialog, 
    DialogActions, 
    DialogContentText, 
    DialogContent, 
    DialogTitle, 
    Slide, 
    IconButton } from '@material-ui/core'
import TutorialOneToOneDetail from '../../assets/TutorialOneToOneDetail.png'
import { INVITATIONS } from '../../constants/routes'
import { Link as RouterLink } from 'react-router-dom'
import { ArrowBack, Close } from '@material-ui/icons'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme) => ({

    backIcon:{
       cursor:'pointer'
    },
    img:{
       width:'40%',
       objectFit:'cover',
       [theme.breakpoints.down(1000)]: {
            width:'60%'
        },
       [theme.breakpoints.down(600)]: {
           width:'80%'
       }
    },
    modalTitle: {
        textAlign: 'center',
        padding:"18px 10px 18px 10px",
        margin: '5px 0px 15px 0px',
        backgroundColor:"#f9f6ff",
        borderRadius:'20px',
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        gap:'8px',
        [theme.breakpoints.down(800)]: {
            flexDirection:'column',
            gap:'15px',
        }
    },
    dialogTitle:{
        width:'100%'
    },
    box:{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"flex-end",
        [theme.breakpoints.down(500)]: {
            justifyContent:'center'
        },

    },
    dialogContent:{
       width:"100%",
       height:'100%',
       fontSize:'18px',
       textAlign:'center',
       marginBottom:'30px',
       display:'flex',
       flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
       [theme.breakpoints.down(600)]: {
           padding:'0px 20px 0px 20px',
       }

    },
    dialogTop:{
        width:"100%",
        display:'flex',  
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        gap:"20px",
        marginBottom:'30px',
        [theme.breakpoints.down(1000)]: {
            flexDirection:'column',

        }
    },
    contentImage:{
        width: '40%',
        [theme.breakpoints.down(1000)]: {
            width:"70%"  
        },
        [theme.breakpoints.down(600)]: {
            width:"100%"  
        },
    },
    contentText:{
       color:"#6e6f71",
       width:'60%',
       fontSize:'16px',
       display:'flex',
       flexDirection:'column',
       justifyContent:'center',
       alignItems:'start',
       gap:"30px",
       [theme.breakpoints.down(1000)]: {
        width:"80%"  
      }
    },
    notification:{
        width:"100%",
        textAlign:'center',
        fontWeight:'bold',
        color:"#6e6f71",
        fontSize:"14px",
        [theme.breakpoints.down(1000)]: {
            width:"80%"  
        }
    },
    container:{
        cursor:"pointer",
        boxShadow:"6px 6px 12px 6px rgba(0, 0, 0, 0.12)",
        borderRadius:"8px",
        height:'220px',
        width:'180px',
        padding: '10px 20px 20px 20px',
        display:'flex',
        flexDirection:'column',
        gap:'20px',
        justifyContent:'center',
        alignItems:'center',
        fontSize:'12px',
    },
    subTitle:{
       fontWeight:"bold"
    },
    modalBox: {
        '& .MuiPaper-root': {
            width: '80%',
            maxWidth: '900px',
            borderRadius: '30px',
            padding: '10px 30px',
        }
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginBottom: '5%',
        gap: '20px',
        [theme.breakpoints.down('900')]: {
            flexDirection: 'column',
            gap: '20px'
        },

    },
    leftButton: {
        width:'280px',
        border:'1px solid #000',
        backgroundColor: '#fff',
        color:"#000",
        borderRadius: '20px',
        padding: '1px 30px 1px 30x',
        '&:hover': {
            backgroundColor: '#fff',
        },
        [theme.breakpoints.down(400)]: {
            width:'90%'
        }

    },
    rightButton: {
        width:'280px',
        backgroundColor: '#000',
        color: '#fff',
        borderRadius: '20px',
        padding: '5px 20px 5px 20px',
        '&:hover': {
            backgroundColor: '#000',
        },
        "&:disabled": {
            backgroundColor: '#eeeeee',
        },
        [theme.breakpoints.down(400)]: {
            width:'90%'
        }
    },
    input:{
        width:'100%',
        borderRadius:'20px',
        border:'0.5px solid #b6b6b6',
        marginBottom:'20px',
        height:'40px',
        padding:'10px',
        "&:focus": {
            outline:"none"
        },
    }



}))

const OneToOne = ({ onClose, open, handleNavigate }) => {
    

 
    const classes = useStyles()

    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            className={classes.modalBox}
        >
            <Typography variant="h4" paragraph className={classes.modalTitle}>
                <ArrowBack className={classes.backIcon} onClick={()=>handleNavigate('welcome')}/> 
                Let's go deeper together! 📊  
                <Close className={classes.backIcon} onClick={onClose}/> 
            </Typography>

            <div className={classes.dialogContent}>
                <div className={classes.dialogTop}>
                   
                    <img src={TutorialOneToOneDetail} className={classes.img}/>
                    

                    <div className={classes.contentText}>
                        <div>
                            You can use flöka to enhance your 1:1 services. 
                             can track their lifestyle changes using the flöka app 
                            or the patient portal. As a result, this data can be seen by you, 
                            helping them keep accountable and informed! 
                        </div>
                        <div>
                            To view a sample of the dashboards by exploring our sample user! 
                            Select your sample users profile and click on “View Full Health Data” 
                            to get an in depth look at flöka tracking.
                        </div>
                    </div>
                </div>

                <div className={classes.notification}>
                    Remember to invite a new client onto the platform to begin assigning them to a program!
                </div>
               
        
            </div>
            <DialogActions className={classes.buttonsContainer}>
                <Button 
                    component={RouterLink}
                    to={INVITATIONS}
                    className={classes.leftButton} 
                    onClick={onClose}
                >
                    Invite your First Client
                </Button>

                <Button 
                    className={classes.rightButton} 
                    onClick={()=>{
                       window.open(
                        'https://foregoing-canoe-d1b.notion.site/Invite-New-1-1-Clients-and-Resend-Invitations-d86995a2c29149adbbbd45ccce0c9388', 
                        '_blank')
                    }}
                >
                    View tutorial on how to invite a client
                </Button>
            </DialogActions>
        </Dialog>
    )


}

export default OneToOne