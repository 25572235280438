import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'
import { qb_login } from '../../Chat/index'

/** Practitioner class */
import Practitioner from '../../../classes/Practitioner'

/**
 * Get practitioner profile from DynamoDb for current signin practitioner.
 *
 * @return {Promise<any>}
 */
const getProfile = () => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ id, accessToken }) => {
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
                // console.log(accessToken)
                axios
                    .get(`${baseUrl}/practitioner/${id}`, reqBody)
                    .then(async (res) => {
                        // Store qbId in local storage for later use
                        console.log('res')
                        console.log('res')
                        console.log('res')
                        console.log(res)
                        if (localStorage.getItem('qbId') == null) {
                            localStorage.setItem('qbId', res.data.qb_id)
                            qb_login(res.data.qb_id)
                        } else {
                            qb_login(res.data.qb_id)
                        }
                        if (localStorage.getItem('isFirstLogin') == null) {
                            localStorage.setItem(
                                'isFirstLogin',
                                res.data.is_first_login
                            )
                        }
                        if (
                            localStorage.getItem('fsId') === null &&
                            res.data.fs_id
                        ) {
                            localStorage.setItem('fsId', res.data.fs_id)
                        }
                        if (
                            localStorage.getItem('fsAccessToken') === null &&
                            res.data.fs_access_token
                        ) {
                            localStorage.setItem(
                                'fsAccessToken',
                                res.data.fs_access_token
                            )
                        }
                        if (
                            localStorage.getItem('fsRefreshToken') === null &&
                            res.data.fs_refresh_token
                        ) {
                            localStorage.setItem(
                                'fsRefreshToken',
                                res.data.fs_refresh_token
                            )
                        }
                        if (
                            localStorage.getItem('fsAccessTokenExpiredAt') ===
                                null &&
                            res.data.fs_access_token_expired_at
                        ) {
                            localStorage.setItem(
                                'fsAccessTokenExpiredAt',
                                res.data.fs_access_token_expired_at
                            )
                        }
                        if (
                            localStorage.getItem('fsLocation') === null &&
                            res.data.fs_location
                        ) {
                            localStorage.setItem(
                                'fsLocation',
                                res.data.fs_location
                            )
                        }
                        console.log('res data', res.data);
                        // console.log('new prac data', new Practitioner(res.data));
                        resolve(new Practitioner(res.data))
                        return
                    })
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default getProfile
