/** Action types */
const SET_PRAC_ID = 'SET_PRAC_ID'   
const SET_EN_BD = 'SET_EN_BD'
const SET_FIRST_LOGIN = 'SET_FIRST_LOGIN'
const SET_IS_AUTH = 'SET_IS_AUTH'
const SET_IS_FULL = 'SET_IS_FULL'
const SET_PATIENTS = 'SET_PATIENTS'
const SET_PATIENT_DATA = 'SET_PATIENT_DATA'
const SET_PATIENT_LIST = 'SET_PATIENT_LIST'
const SET_ARCHIVED = 'SET_ARCHIVED'
const SET_LOCKED = 'SET_LOCKED'
const SET_REMAINING_SEATS = 'SET_REMAINING_SEATS'
const SET_STRIPE_ID = 'SET_STRIPE_ID'
const SET_SUBS_PKGS = 'SET_SUBS_PKGS'
const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION'
const SET_UNRESPONDED_REQ_COUNT = 'SET_UNRESPONDED_REQ_COUNT'
const SET_ONE_TO_ONE_COURSES = 'SET_ONE_TO_ONE_COURSES'
const SET_GET_ALL_DATA ='SET_GET_ALL_DATA'
const SET_COURSE_SASS ='SET_COURSE_SASS'
const SET_MAILCHIMP ='SET_MAILCHIMP'
const SET_INCOMING_INVITATION_FROM_CLIENT = 'SET_INCOMING_INVITATION_FROM_CLIENT'
const SET_INCOMING_INVITATION_FROM_CLIENT_FILTERED = 'SET_INCOMING_INVITATION_FROM_CLIENT_FILTERED'
const SET_SHOWN_TUTORIAL = 'SET_SHOWN_TUTORIAL'
const SET_SHOWN_NOTIFICATION ='SET_SHOWN_NOTIFICATION'
const SET_INTAKE_FORM_SUBSCRIPTION = 'SET_INTAKE_FORM_SUBSCRIPTION'
const SET_CLINIC_DATA = 'SET_CLINIC_DATA'
const SET_CLINIC_DATA_REQUESTS = 'SET_CLINIC_DATA_REQUESTS'
const SET_CLINIC_IDS = 'SET_CLINIC_IDS'
const SET_CLINIC_REQUESTS = 'SET_CLINIC_REQUESTS'
const SET_CLINIC_PATIENTS_ASSIGNED_PRACTITIONER = 'SET_CLINIC_PATIENTS_ASSIGNED_PRACTITIONER'

export {
    SET_PRAC_ID,
    SET_EN_BD,
    SET_FIRST_LOGIN,
    SET_IS_AUTH,
    SET_IS_FULL,
    SET_PATIENTS,
    SET_PATIENT_DATA,
    SET_PATIENT_LIST,
    SET_ARCHIVED,
    SET_LOCKED,
    SET_REMAINING_SEATS,
    SET_STRIPE_ID,
    SET_SUBS_PKGS,
    SET_SUBSCRIPTION,
    SET_UNRESPONDED_REQ_COUNT,
    SET_ONE_TO_ONE_COURSES,
    SET_GET_ALL_DATA,
    SET_COURSE_SASS,
    SET_MAILCHIMP,
    SET_INCOMING_INVITATION_FROM_CLIENT,
    SET_INCOMING_INVITATION_FROM_CLIENT_FILTERED,
    SET_SHOWN_TUTORIAL,
    SET_SHOWN_NOTIFICATION,
    SET_INTAKE_FORM_SUBSCRIPTION,
    SET_CLINIC_DATA,
    SET_CLINIC_DATA_REQUESTS,
    SET_CLINIC_IDS,
    SET_CLINIC_REQUESTS,
    SET_CLINIC_PATIENTS_ASSIGNED_PRACTITIONER
}

/** Reducer switch statements */
export default function reducer(state, action) {
    switch (action.type) {
        case SET_GET_ALL_DATA:
            return { ...state, getAllData: action.value }
        case SET_PRAC_ID:
            return { ...state, id: action.value }
        case SET_EN_BD:
            return { ...state, enBd: action.value }
        case SET_FIRST_LOGIN:
            return { ...state, firstLogin: action.value }
        case SET_IS_AUTH:
            return { ...state, isAuth: action.value }
        case SET_IS_FULL:
            return { ...state, isFull: action.value }
        case SET_REMAINING_SEATS:
            return { ...state, remainingSeats: action.value }
        case SET_PATIENTS:
            return { ...state, patients: action.value }
        case SET_PATIENT_DATA:
            return { ...state, patientData: action.value }
        case SET_PATIENT_LIST:
            return { ...state, patientList: action.value }
        case SET_PATIENTS:
            return { ...state, patients: action.value }
        case SET_ARCHIVED:
            return { ...state, archived: action.value }
        case SET_LOCKED:
            return { ...state, locked: action.value }
        case SET_STRIPE_ID:
            return { ...state, stripeId: action.value }
        case SET_SUBS_PKGS:
            return { ...state, subsPkgs: action.value }
        case SET_SUBSCRIPTION:
            return { ...state, subscription: action.value }
        case SET_UNRESPONDED_REQ_COUNT:
            return { ...state, unrespondedReqCount: action.value }
        case SET_ONE_TO_ONE_COURSES:
            return { ...state, oneToOneCourses: action.value }
        case SET_COURSE_SASS:
            return { ...state, courseSASS: action.value }
        case SET_MAILCHIMP:
            return { ...state, mailchimp: action.value }
        case SET_INCOMING_INVITATION_FROM_CLIENT:
            return { ...state, invitations: action.value }
        case SET_INCOMING_INVITATION_FROM_CLIENT_FILTERED:
            return { ...state, invitationsNotified: action.value }
        case SET_SHOWN_TUTORIAL:
            return { ...state, shownTutorial: action.value }
        case SET_SHOWN_NOTIFICATION:
            return { ...state, shownNotification: action.value }
        case SET_INTAKE_FORM_SUBSCRIPTION:
            return { ...state, intakeformSubscription: action.value }
        case SET_CLINIC_DATA:
            return { ...state, clinicProfile: true, clinicPractitioners: action.value.clinicPractitioner, clinicRequests: action.value.clinicPractitionerRequests }
        case SET_CLINIC_PATIENTS_ASSIGNED_PRACTITIONER:
            return { ...state, clientsAssignedToPractitioners: action.value }
        case SET_CLINIC_IDS:
            return { ...state, clinicIds: action.value }
        case SET_CLINIC_REQUESTS:
            return { ...state, clinicRequests: action.value }
        default:
            throw new Error(
                `App::reducer::error - Invalid action type: ${action.type}`
            )
    }
}
