import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'

/**
 * Replace the element at position @param data.index from the 'schedules' list
 * in Program Patient table with @param newProgram for current signin practitioner.
 *
 * @param {Object} data
 * @param {String} attr
 * @param {Any} val
 * @return {Promise<any>}
 */
const updatePatientSchedule = (data, val, isComment) => {
    console.log(isComment)
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ id, accessToken }) => {
                const params = { data, val, isComment }
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }

                axios
                    .put(
                        `${baseUrl}/practitioner/${id}/schedule/update`,
                        params,
                        reqBody
                    )
                    .then((res) => resolve(res.data))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default updatePatientSchedule
