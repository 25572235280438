import React from 'react'
import ReactDOM from 'react-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import App from './App'
import * as serviceWorker from './elements/serviceWorker'
import './styles/index.css'
import FlokaTheme from './styles/Theme'


ReactDOM.render(
    <MuiThemeProvider theme={FlokaTheme}>
        <App />
    </MuiThemeProvider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
