import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { SIGN_IN } from '../constants/routes'
import { authConfig } from '../lib/Auth/index'

function PrivateRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={(routeProps) =>
                authConfig.getUser() !== null ? (
                    <Component {...routeProps} {...rest} />
                ) : (
                    <Redirect to={SIGN_IN} />
                )
            }
        />
    )
}

export default PrivateRoute
