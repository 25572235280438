import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'

/**
 * Get patient's email with @param patientId.
 *
 * @param {String} patientId Patient's Firebase id
 * @return {Promise<String>} patient's email
 */
const getEmail = (patientId) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ accessToken }) => {
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
                axios
                    .get(`${baseUrl}/patients/${patientId}/email`, reqBody)
                    .then((res) => resolve(res.data))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default getEmail
