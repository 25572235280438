/**
 * Create new 1-1 private dialog for current signin practitioner and @param patientQbId
 * Reference: https://docs.quickblox.com/docs/js-chat#create-1-1-dialog
 *
 * @param {Number} patientQbId - patient's QuickBlox id
 * @return {Promise} Newly created dialog, if there's existing dialog, return the existing dialog
 */
function createDialog(patientQbId) {
    return new Promise((resolve, reject) => {
        const params = {
            type: 3,
            occupants_ids: [patientQbId],
        }
        QB.chat.dialog.create(params, (err, conversation) => {
            if (err) {
                reject(err)
            } else {
                resolve(conversation)
            }
        })
    })
}

export default createDialog
