import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'

/**
 * Remove a package at position @param packageIndex from the 'subscription_packages' list
 * for current signin practitioner.
 *
 * @param {Number} packageIndex
 * @param {Promise}
 */
const removePackage = (packageIndex) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ id, accessToken }) => {
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                    data: { packageIndex },
                }
                axios
                    .delete(
                        `${baseUrl}/practitioner/${id}/packages/delete`,
                        reqBody
                    )
                    .then((res) => resolve(res))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default removePackage
