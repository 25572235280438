import axios from 'axios'
import { populateIndex } from '../../../helpers/generalHelpers'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'

/**
 * Remove a module at position @param data from both 'programs.modules' and 'schedules' list
 * for current signin practitioner.
 *
 * @param {Object} data
 * @return {Promise<any>}
 */
const removeModule = (data) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ id, accessToken }) => {
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                    data: { data },
                }
                axios
                    .delete(
                        `${baseUrl}/practitioner/${id}/module/delete`,
                        reqBody
                    )
                    .then((res) => {
                        const modules = res.data.modules ? res.data.modules : []
                        const result = { modules: populateIndex(modules) }
                        resolve(result)
                    })
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default removeModule
