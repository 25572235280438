import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'
import { getClientId } from '../../Auth/fullscript'

import { decrypt, getAge, newDecrypt } from '../../Patient/index'
import { get_patient_programs } from '../Patients'

/**
 * Fetch all patient-related data from Firebase for each patient id in @param patientsId.
 *
 * @param {String[]} patientsId - An array of patients' Firebase ids
 * @return {Promise<any>}
 */
const updateFullScriptToken = (practitionerID, country) => {
    
    return new Promise(async (resolve, reject) => {
        try {
            const clientId = await getClientId(country)
            console.log(clientId)
            const { accessToken } = await getAuthSession()
            const reqBody = {
                headers: { Authorization: `Bearer ${accessToken}` },
            }
            const params = { practitionerID, clientId }
           console.log(practitionerID, clientId)
            const responseArr = await axios.post(`${baseUrl}/fullscript/initialUpdateToken`,params, reqBody)
            console.log('holaaa')
        
            resolve(responseArr.data)
        } catch (err) {
            reject(`getPatients::error - ${JSON.stringify(err)}`)
        }
    })
}

export default updateFullScriptToken
