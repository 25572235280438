/**
 * Return Fullscript Client Id with @param label
 */

const fullscriptClientId =
    process.env.REACT_APP_NODE_ENV === 'production'
        ? {
              ca: process.env.REACT_APP_FULLSCRIPT_CLIENT_ID_CA,
              us: process.env.REACT_APP_FULLSCRIPT_CLIENT_ID_US,
          }
        : {
              ca: process.env.REACT_APP_FULLSCRIPT_TEST_CLIENT_ID_CA,
              us: process.env.REACT_APP_FULLSCRIPT_TEST_CLIENT_ID_US,
          }

export const getClientId = (label) => {
    return fullscriptClientId[label]
}

/**
 * Return Fullscript environment for iFrame with @param label
 */
const fullscriptEnv =
    process.env.REACT_APP_NODE_ENV === 'production'
        ? {
              ca: 'ca',
              us: 'us',
          }
        : {
              ca: 'ca-snd',
              us: 'us-snd',
          }

export const getEnv = (label) => {
    return fullscriptEnv[label]
}
