import React , { useState } from 'react'
import { 
    Grid, 
    Box, 
    makeStyles, 
    Button, 
    Typography, 
    Dialog, 
    DialogActions, 
    DialogContentText, 
    DialogContent, 
    DialogTitle, 
    Slide, 
    IconButton } from '@material-ui/core'
import clsx from 'clsx'
import { Link as RouterLink } from 'react-router-dom'
import { PROGRAMS } from '../../constants/routes'
import { ArrowBack, Close } from '@material-ui/icons'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme) => ({
    backIcon:{
        cursor:'pointer'
    },
    img:{
       width:'150px'
    },
    modalTitle: {
        textAlign: 'center',
        padding:"18px 10px 18px 10px",
        margin: '5px 0px 15px 0px',
        backgroundColor:"#f9f6ff",
        borderRadius:'20px',
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        gap:'8px',
        [theme.breakpoints.down(680)]: {
            flexDirection:'column',
            gap:'15px'
        }
    },
    dialogTitle:{
        width:'100%'
    },
    box:{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"flex-end",
        [theme.breakpoints.down(500)]: {
            justifyContent:'center'
        },

    },
    dialogContent:{
       color:'#6e6f71',
       fontSize:'18px',
       height:'100%',
       textAlign:'center',
       padding:'0px 20px 0px 20px',
       marginTop:'30px',
       marginBottom:'50px',
       display:'flex',  
       flexDirection:'column',
       justifyContent:'center',
       alignItems:'center',
       gap:"30px",
       [theme.breakpoints.down(600)]: {
           padding:'0px 20px 0px 20px',
       }

    },
    container:{
        cursor:"pointer",
        boxShadow:"6px 6px 12px 6px rgba(0, 0, 0, 0.12)",
        borderRadius:"8px",
        height:'240px',
        width:'180px',
        padding: '10px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        fontSize:'12px',
    },
    subTitle:{
       fontWeight:"bold"
    },
    text:{
       color:"#6e6f71"
    },
    modalBox: {
        '& .MuiPaper-root': {
            width: '80%',
            maxWidth: '900px',
            borderRadius: '30px',
            padding: '10px 30px',
        }
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginBottom: '5%',
        gap: '20px',
        [theme.breakpoints.down('900')]: {
            flexDirection: 'column',
            gap: '20px'
        },

    },
    leftButton: {
        border:'1px solid #000',
        width:'220px',
        backgroundColor: '#fff',
        color:"#000",
        borderRadius: '20px',
        padding: '5px 20px 5px 20x',
        '&:hover': {
            backgroundColor: '#fff',
        },

    },
    rightButton: {
        width:'220px',
        backgroundColor: '#000',
        color: '#fff',
        borderRadius: '20px',
        padding: '5px 20px 5px 20px',
        '&:hover': {
            backgroundColor: '#000',
        },
        "&:disabled": {
            backgroundColor: '#eeeeee',
        }
    },
    input:{
        width:'100%',
        borderRadius:'20px',
        border:'0.5px solid #b6b6b6',
        marginBottom:'20px',
        height:'40px',
        padding:'10px',
        "&:focus": {
            outline:"none"
        },
    }



}))

const Program = ({ onClose, open, handleNavigate }) => {
    

 
    const classes = useStyles()

    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            className={classes.modalBox}
        >
            <Typography variant="h4" paragraph className={classes.modalTitle}>
                <ArrowBack className={classes.backIcon} onClick={()=>handleNavigate('welcome')} />
                Woo! We love to see it. 🚀
                <Close className={classes.backIcon} onClick={onClose} />
            </Typography>

            <div className={classes.dialogContent}>
                <div>
                    By creating a program on flöka you are able to set up your clients wth 
                    daily tasks that can help them with creating long lasting lifestyle changes. A
                    dd tasks and content to your daily program. 
                </div>

                <div>
                    Programs are complimentary with your 1:1 seat plan! 
                    So invite your first client onto the platform and get started with developing 
                    a program.
                </div>

                <div className={classes.subTitle}>
                    Remember to invite a new client onto the platform to begin 
                    assigning them to a program!
                </div>
        
            </div>
            <DialogActions className={classes.buttonsContainer}>
                <Button 
                    component={RouterLink}
                    className={classes.leftButton} 
                    onClick={onClose}
                    to={PROGRAMS}
                >
                    Start Creating Programs Now
                </Button>

                <Button 
                    className={classes.rightButton} 
                    disabled={false}
                    onClick={()=>{
                        window.open('https://foregoing-canoe-d1b.notion.site/How-to-create-and-assign-programs-4a459ee2e10f49c495d9b7d78ca246f2?pvs=4', '_blank')
                    }}
                >
                    View tutorial on Programs
                </Button>
            </DialogActions>
        </Dialog>
    )


}

export default Program