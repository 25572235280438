import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'
import { populateIndex } from '../../../helpers/generalHelpers'

/**
 * Add new general note to patient with @param patientId 's profile.
 *
 * @param {String} patientId Patient's Firebase id
 * @param {Object} note item
 * @return {Promise<any>} Updated note list
 */
const addFile = (patientId, file) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ accessToken }) => {
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                    Accept: 'application/json',
                    'Content-Type': `multipart/form-data; boundary=${file._boundary}`,
                }
                axios
                    .put(
                        `${baseUrl}/patients/${patientId}/files/new`,
                        file,
                        reqBody
                    )
                    .then((res) => resolve(populateIndex(res.data.files)))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default addFile
