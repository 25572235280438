import { getUser } from './config'
import { delete_qb_user } from '../Chat/index'

const deleteAccount = () => {
    const cognitoUser = getUser()

    if (cognitoUser) {
        return new Promise((resolve, reject) => {
            cognitoUser.getSession((err, session) => {
                if (err) {
                    reject(err.message || JSON.stringify(err))
                } else {
                    const id = session.accessToken.payload.sub
                    cognitoUser.deleteUser((err, _) => {
                        if (err) {
                            reject(err.message || JSON.stringify(err))
                        } else {
                            // console.log("Deleted Cognito user.");
                            resolve(id)
                            // delete_qb_user()
                            // .then(() => resolve(id))
                            // .catch(err => console.log(err));
                        }
                    })
                }
            })
        })
    } else {
        throw new Error('no cognitiveUser value')
    }
}

export default deleteAccount
