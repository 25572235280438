import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'

/**
 * Update note list for patient with @param patientId 's profile
 * from current sigin practitioner.
 *
 * @param {String} patientId Patient's Firebase id
 * @param {Number} index Index of note to be removed from the notes list
 * @param {Object} newItem note item
 * @param {string} label notes
 * @return {Promise<any>}
 */
const updateFbCycleEntry = (key, newKey, entry, newEntry, patientId) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ id, accessToken }) => {
                const params = {
                    key,
                    newKey,
                    entry,
                    newEntry,
                    patientId,
                    practitionerId: id,
                }
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
                axios
                    .put(
                        `${baseUrl}/patients/${patientId}/cycle/update`,
                        params,
                        reqBody
                    )
                    .then((res) => resolve(res.data))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default updateFbCycleEntry
