/**
 * update newsLetter branch if practitioner wants to accept newsLetter from floka
 *
 * @param {String} courseData parsed data to send to the backend
 */

import { getAuthSession } from '../../Auth/index'
import axios from 'axios'
import { baseUrl } from '../config'



async function saveNewsLetterInfo( newPractitionerID, email) {




    return new Promise(async (resolve, reject) => {

        const reqBody = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
     
            },
        }
        const params ={
            practitionerId:  newPractitionerID,
            email: email
        }
        axios
            .post(
                `${baseUrl}/webhook/newsLetter`,
                params,
                reqBody
            )
            .then((res) => resolve(res.data))
            .catch((err) => reject(JSON.stringify(err)))
    })
        

}

export default saveNewsLetterInfo