import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'
import { decrypt, getAge, newDecrypt } from '../../Patient/index'
import { get_patient_programs } from '../Patients'


/**
 * Fetch a single patient data from Firebase for each patient id in @param patientsId.
 *
 * @param {String[]} patientsId - An array of patients' Firebase ids
 * @return {Promise<any>}
 */

 const getPractitionerClientsList = () => {

    return new Promise((resolve, reject) => {
        getAuthSession()
        .then(async ({ accessToken, id }) => {

            const reqBody = {
                headers: { Authorization: `Bearer ${accessToken}` },
            }

            axios.get(`${baseUrl}/practitioner/clinic/get-clinics-invitation/${id}`,reqBody)
                .then((res) =>  resolve(res.data))
                .catch((err) =>{ 
                    reject(JSON.stringify(err))
                })
            
        })

    })
 }


 export default getPractitionerClientsList;