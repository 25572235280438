/**
 * @description This file contains all validation-related helper functions.
 */

import { stringToDate } from './dateHelpers'

/**
 * Check if @param value is an empty string.
 *
 * @param {String} value
 * @return {Boolean} True if @param value is an empty string, false otherwise
 */
export const isEmptyString = (value) => {
    const empty_ws_regex = /\S/
    return !empty_ws_regex.test(value)
}

/**
 * Check if @param value has a valid email format.
 *
 * @param {String} value
 * @return {Boolean} True if @param value has a valid email format, false otherwise
 */
export const isValidEmail = (value) => {
    const email_regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return email_regex.test(value)
}

/**
 * Check if @param type is one of jpeg, jpg and png.
 *
 * @param {String} type
 * @return {Boolean} True if @param value is one of jpeg, jpg and png, false otherwise
 */
export const isValidImageFormat = (type) => {
    const validFormat = ['image/jpeg', 'image/jpg', 'image/png']
    return validFormat.includes(type)
}

/**
 * Check if @param value has a valid phone number format.
 *
 * @param {String} value
 * @return {Boolean} True if @param value has a valid phone number format, false otherwise
 */
export const isValidPhoneNumber = (value) => {
    const phone_no_regex = /([\s]+)/g
    return !(value.match(phone_no_regex).length > 1)
}

/**
 * Check if @param value has a valid url format.
 *
 * @param {String} value
 * @return {Boolean} True if @param value has a valid url format, false otherwise
 */
export const isValidUrl = (value) => {
    const expression =
        /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
    const url_regex = new RegExp(expression)
    return value.match(url_regex)
}

/**
 * Performs a deep comparison between two values to determine if they are equivalent.
 * Reference: https://lodash.com/docs#isEqual
 *
 * @param {Object} value
 * @param {Object} other
 * @return {Boolean} True if @param value and @param other are equivalent, false otherwise
 */
export const isObjectEqual = require('lodash.isequal')

/**
 * Validate a date string
 *
 * @param {String} d date to check
 *                 Must be in the format of yyyy-mm-dd
 *                 eg. 2020-03-12
 * @return  {Boolean}  true if d is a valid date or vice versa
 */
export const validDate = (d) => {
    let bits = d.split('-')
    let t = stringToDate(d)
    return t.getFullYear() == bits[0]
}

/**
 * Validate the subscription package to recommend to a patient.
 *
 * @param {Object} pkg subscription package
 * @param {bool} checked one time payment toggled
 * @return {String} return error message if @param pkg is invalid
 */
export const isValidSubPkg = (pkg, checked, checkedPayments) => {
    if (Number(pkg.price) < 0) return 'Membership price cannot be less than $0.'
    // interval
    if (Number(pkg.interval) < 1) return 'Interval must be greater than 0'

    if (pkg.unit === 'week' && Number(pkg.interval) > 52)
        return "The interval for weekly prices can't be greater than 52."

    if (pkg.unit === 'month' && Number(pkg.interval) > 12)
        return "The interval for monthly prices can't be greater than 12."

    if (pkg.unit === 'year' && Number(pkg.interval) > 1)
        return "The interval for yearly prices can't be greater than 1."

    // period
    if (!checked && checkedPayments && pkg.period === '')
        return 'Please select a number for monthly payments.'

    if (Number(pkg.period) > 5)
        return "The number of payment period can't be greater than 5."
}

/**
 * Validate the user profile to make sure the fields required for marketplace
 * are complete.
 * Required fields include:
 * - At least one certification
 * - At least one special interest
 * - Professional Bio
 * - Valid address
 * - At least one served region
 * - Hours
 *
 * @param {Practitioner} p
 * @return {Promise}  true if @param p is a complete profile false otherwise
 */
export const isPublishable = (p) => {
    return new Promise(async (resolve, reject) => {
        if (p.isPublishReady) {
            resolve()
            return
        }
        if (p.certifications.length < 1) {
            reject(
                `Failed to publish profile. At least one 'Certifications' is required to publish your profile.`
            )
            return
        }
        if (p.specialInterests.length < 1) {
            reject(
                `Failed to publish profile. At least one 'Special Interests' is required to publish your profile.`
            )
            return
        }
        if (isEmptyString(p.professionalBio)) {
            reject(
                `Failed to publish profile. 'Professional Bio' is required to publish your profile.`
            )
            return
        }
        if (
            isEmptyString(p.address) ||
            (p.geolocation.lat === 0 && p.geolocation.lng === 0)
        ) {
            reject(
                `Failed to publish profile. A valid address is required to publish your profile.`
            )
            return
        }
        if (p.servedRegions.length < 1) {
            reject(
                `Failed to publish profile. At least one 'Served Regions' is required to publish your profile.`
            )
            return
        }
        if (isEmptyString(p.hours)) {
            reject(
                `Failed to publish profile. 'Hours' is required to publish your profile.`
            )
            return
        }
        resolve()
        return
    })
}
