/**
 * Get an array of presribed supplement names for a patient
 *
 * @param {Object} userData
 * @return {String[]}  An array of supplement names,
 *                  return ["None"] if there is no any prescibed supplement
 */
function getSupplements(userData) {
    if (
        !userData ||
        !('nutrition' in userData) ||
        !('savedVitamins' in userData.nutrition)
    ) {
        return ['None']
    } else {
        const vitaminObjects = Object.values(userData.nutrition.savedVitamins)
        return vitaminObjects
            .map((vitamin) => vitamin.name)
            .filter((name) => name !== undefined)
    }
}

export default getSupplements
