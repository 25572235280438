import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'


const acceptInviteClinicAsPractitioner = (clinicId) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ id, accessToken }) => {

                const params = { clinicId: clinicId, practitionerId: id }
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
                axios
                    .post(
                        `${baseUrl}/practitioner/clinic/accept-invite-as-practitioner`,
                        params,
                        reqBody
                    )
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default acceptInviteClinicAsPractitioner
