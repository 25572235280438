import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'
import { qb_login } from '../../Chat/index'

/** Practitioner class */
import Practitioner from '../../../classes/Practitioner'

/**
 * Get practitioner profile from DynamoDb for current signin practitioner.
 *
 * @return {Promise<any>}
 */
const getInviteFromClient = (email) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ id, accessToken }) => {
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
                const params={
                    email:email
                }
                // console.log(accessToken)
                axios
                    .post(`${baseUrl}/practitioner/${id}/invitation-from-client`, params, reqBody)
                    .then(async (res) => {
                        console.log('res data', res.data);
                        resolve(res.data)
                        return
                    })
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getInviteFromClient::error - ${JSON.stringify(err)}`)
            )
    })
}

export default getInviteFromClient