import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'

const getClinicResourcesPract = () => {

    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(async ({ accessToken, id }) => {

                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }

                axios.get(`${baseUrl}/practitioner/${id}/clinicResources`, reqBody)
                    .then((res) => resolve(res.data))
                    .catch((err) => {
                        reject(JSON.stringify(err))
                    })

            })

    })
}


export default getClinicResourcesPract;