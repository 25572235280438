import axios from 'axios'
import { populateIndex } from '../../../helpers/generalHelpers'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'

/**
 * Remove a program at position @param data from both 'programs' and 'schedules' list.
 * for current signin practitioner.
 *
 * @param {Object} data
 * @return {Promise<any>}
 */
const removeProgramSchedule = (data) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ id, accessToken }) => {
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                    data: { data },
                }

                axios
                    .delete(
                        `${baseUrl}/practitioner/${id}/program/delete`,
                        reqBody
                    )
                    .then((res) => resolve(populateIndex(res.data.programs)))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default removeProgramSchedule
