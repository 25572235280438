import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'
import { decrypt, getAge, newDecrypt } from '../../Patient/index'
import { get_patient_programs } from '../Patients'

/**
 * Fetch all patient-related data from Firebase for each patient id in @param patientsId.
 *
 * @param {String[]} patientsId - An array of patients' Firebase ids
 * @return {Promise<any>}
 */
const getPatients = (patientsId, archivedIds) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { accessToken } = await getAuthSession()
            const reqBody = {
                headers: { Authorization: `Bearer ${accessToken}` },
            }
            // console.log(patientsId)

            const allRequests = patientsId.map((id) =>
                axios.get(`${baseUrl}/patients/${id}`, reqBody)
            )
            const responseArr = await axios.all(allRequests)
            const validPatients = responseArr
                .map((res) => res.data)
                .filter((patient) => Object.values(patient)[0] !== null)

            

            console.log('validPatients', validPatients)

            // Filter valid patients and fetch programs
            const validIds = validPatients
                .map((el) => Object.keys(el)[0])
                .filter((id) => !archivedIds.includes(id))

            const allPrograms = validIds.map(async (id) => {
                const data = await get_patient_programs(id)
                data['id'] = id
                return data
            })

            const programArr = await Promise.allSettled(allPrograms)
            const validPrograms = programArr.filter(
                (el) => el.status === 'fulfilled'
            )

            let users = {}
            validPatients.forEach((patient, i) => {
                const id = Object.keys(patient)[0]
                const programs = validPrograms.filter(
                    (el) => el.value.id === id
                )
                if (archivedIds && !archivedIds.includes(id)) {
                    users[id] = Object.values(patient)[0]
                    if (programs.length) {
                        let data = programs[0].value
                        data.compliance.forEach((comp) => {
                            comp.type = comp.type.replace('compliance', '')
                        })
                        data.programs = data.programs.filter(
                            (pro) => pro.program_length > 1
                        )
                        users[id]['program'] = {
                            compliance: data.compliance,
                            programs: data.programs,
                            syncedPrograms: data.syncedPrograms,
                        }
                    }
                } else {
                    const data = Object.values(patient)[0]
                    const basicInfo = data?.basicInfo ? data?.basicInfo : {}
                    const subsInfo = data?.subsInfo ? data?.subsInfo : {}
                    users[id] = { basicInfo, subsInfo, status: 'archived' }
                }
                users[id].id = id
                users[id].index = i

                let firstName
                if (users?.[id]?.basicInfo?.firstName) {
                    firstName = decrypt(users?.[id]?.basicInfo?.firstName)
                } else if (users?.[id]?.basicInfo?.name) {
                    firstName = newDecrypt(users?.[id]?.basicInfo?.name)
                } else {
                    firstName = 'None'
                }

                let age
                if (users?.[id]?.basicInfo?.birthDate) {
                    age = decrypt(users?.[id]?.basicInfo?.birthDate)
                } else if (users?.[id]?.basicInfo?.birth) {
                    age = newDecrypt(users?.[id]?.basicInfo?.birth)
                } else {
                    age = 'Not available'
                }

                if (age !== 'Not available') age = getAge(age)

                if (!users[id].basicInfo) users[id].basicInfo = {}

                users[id].basicInfo.firstName =
                    firstName.charAt(0).toUpperCase() + firstName.slice(1)
                users[id].basicInfo.age = age
            })
            resolve(users)
        } catch (err) {
            reject(`getPatients::error - ${JSON.stringify(err)}`)
        }
    })
}

export default getPatients
