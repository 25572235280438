/** Practitioner Class */

export default class Practitioner {
    constructor(pracDbModel) {
        if (typeof pracDbModel !== 'undefined') {
            console.log('pracDbModel', pracDbModel);
            // Construct new Practitioner Object with pracDbModel
            this.id = pracDbModel.practitionerid
            this.birthdate = pracDbModel.birth_date || ''
            this.phone = pracDbModel.phone_number || ''
            this.coachType = pracDbModel.coach_type || ''
            this.userName = pracDbModel.user_name
            this.avatar = pracDbModel.avatar
            this.promoCode = pracDbModel.promo_code || ''
            this.certifications = pracDbModel.certifications
            this.certificateFile = pracDbModel.certificateFile || ''
            this.clinicName = pracDbModel.clinic_name
            this.address = pracDbModel.address
            this.clinicDescription = pracDbModel.clinic_description
            this.coursesList = pracDbModel.coursesList || []
            this.geolocation = pracDbModel.geolocation
            this.hours = pracDbModel.hours
            this.clinicLogo = pracDbModel.clinic_logo
            this.email = pracDbModel.email
            this.firstName = pracDbModel.first_name
            this.isFirstLogin = pracDbModel.is_first_login
            this.isPublishReady = pracDbModel.is_publish_ready
            this.lastName = pracDbModel.last_name
            this.fullscriptId = pracDbModel.fullscript_id
            this.patients = pracDbModel.patients
            this.phoneNumber = pracDbModel.phone_number
            this.professionalBio = pracDbModel.professional_bio
            this.qbId = pracDbModel.qb_id
            this.servedRegions = pracDbModel.served_regions
            this.specialInterests = pracDbModel.special_interests
            this.subscriptionPackages = pracDbModel.subscription_packages
            this.taxConfig = pracDbModel.tax_config
            this.mailchimp = pracDbModel.mailchimp || {}
            this.unrespondedReqCount = pracDbModel.unresponded_request_count
            this.websiteLink = pracDbModel.website_link
            this.stripeId = pracDbModel.stripe_id
            this.purchaseHistory = pracDbModel.purchase_history
            this.totalPaidInv = pracDbModel.total_paid_invitations
            this.archived = pracDbModel.archived ? pracDbModel.archived : []
            this.fullscriptActive = (pracDbModel.fs_refresh_token && pracDbModel.fs_refresh_token !== null) ? true : false
            this.fullscriptCountry = pracDbModel.fs_location || false,
                this.oneToOneCourses = pracDbModel.oneToOneCourses ? pracDbModel.oneToOneCourses : [],
                { ...pracDbModel.coursesSASS ? this.coursesSASS = pracDbModel.coursesSASS : null },
                this.intakeformSubscription = pracDbModel.intake_form_subscription ? pracDbModel.intake_form_subscription : {},
                this.clinicProfile = pracDbModel.clinicProfile ? true : undefined,
                this.clinicPractitioner = pracDbModel.clinicProfile ? pracDbModel.clinicPractitioners : undefined,
                this.clinicPractitionerRequests = pracDbModel.clinicProfile ? pracDbModel.clinicPractitionerRequests : undefined
            this.clinicIds = pracDbModel.clinicIds && pracDbModel.clinicIds?.length ? pracDbModel.clinicIds : undefined
            this.clinicRequests = pracDbModel.clinicRequests && pracDbModel.clinicRequests?.length ? pracDbModel.clinicRequests : undefined
            this.clientsAssignedToPractitioners = pracDbModel.clinicProfile && pracDbModel.clientsAssignedToPractitioners ? pracDbModel.clientsAssignedToPractitioners : undefined


        } else {
            // Construct new Practitioner Object using default values
            this.id = ''
            this.userName = '',
                this.avatar = ''
            this.promoCode = ''
            this.certifications = []
            this.clinicName = ''
            this.address = ''
            this.clinicDescription = ''
            this.coursesList = []
            this.geolocation = { lat: 0, lng: 0 }
            this.hours = ''
            this.clinicLogo = ''
            this.email = ''
            this.firstName = ''
            this.isFirstLogin = true
            this.isPublishReady = false
            this.lastName = ''
            this.fullscriptId = ''
            this.patients = []
            this.phoneNumber = ''
            this.professionalBio = ''
            this.qbId = 0
            this.servedRegions = []
            this.specialInterests = []
            this.subscriptionPackages = []
            this.taxConfig = {
                default_tax_rate: 0,
                tax_reg_no: '',
                tax_regions: [],
            }
            this.mailchimp = {},
                this.unrespondedReqCount = 0
            this.websiteLink = ''
            this.stripeId = ''
            this.purchaseHistory = []
            this.totalPaidInv = 0
            this.archived = []
            this.fullscriptActive = false,
                this.oneToOneCourses = [],
                this.intakeformSubscription = {}
        }
    }
}
