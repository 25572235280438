import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'

/**
 * Replace the element at position @param data.index from the 'syncedPrograms' list
 * in Program Patient table with @param newSyncedProgram for current signin practitioner.
 *
 * @param {String} patId
 * @param {String} syncedProgramId
 * @param {Object} newSyncedProgram
 * @return {Promise<any>}
 */
const updatePatientSyncedProgram = (
    patientId,
    syncedProgramId,
    newSyncedProgram
) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ id, accessToken }) => {
                const params = { patientId, syncedProgramId, newSyncedProgram }
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }

                axios
                    .put(
                        `${baseUrl}/practitioner/${id}/syncedProgram/patient/update`,
                        params,
                        reqBody
                    )
                    .then((res) => resolve(res.data))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default updatePatientSyncedProgram
