/* Types of insights charts based on chartType number */
const chartTitles = {
    0: 'Cycle and Steps Aggregate',
    1: 'Latest Cycle and Steps',
    2: 'Current Cycle and Steps',
    3: 'Cycle and Sleep Aggregate',
    4: 'Steps and Sleep Aggregate',
    5: 'Activities and Sleep Aggregate',
    6: 'Workout and Sleep Total',
    7: 'Meditation Start Time and Average Sleep Hours',
    8: 'Bedtime and Sleep Hours',
    9: 'Meditation Start Time and Sleep Hours',
    10: 'Acne and Cycle Aggregate',
    11: 'Protein Consumed and Sleep Hours',
    12: 'Carbohydrate Consumed and Sleep Hours',
    13: 'Fat Consumed and Sleep Hours',
    18: 'Cycle and Basal Body Temperature Aggregate',
    19: 'Cycle and Basal Body Temperature Total',
}

const chartAxesTitle = {
    0: { x: 'Cycle Progress (%)', y: '# of Steps' },
    1: { x: 'Cycle Date', y: '# of Steps' },
    2: { x: 'Cycle Date', y: '# of Steps' },
    3: { x: 'Cycle Progress', y: 'Sleep Length (hr)' },
    4: { x: '# of Steps', y: 'Sleep Length (hr)' },
    5: { x: '# of Activities', y: 'Average Sleep Length (hr)' },
    6: { x: '# of Steps', y: 'Total Sleep Length (hr)' },
    7: { x: 'Meditation Start Time', y: 'Average Sleep Length (hr)' },
    8: { x: 'Bedtime', y: 'Sleep Length (hr)' },
    9: { x: 'Meditation Start Time', y: 'Sleep Length (hr)' },
    10: { x: 'Cycle Progress (%)', y: 'Per Acne' },
    11: { x: 'Protein Consumed (g)', y: 'Sleep Length (hr)' },
    12: { x: 'Carbohydrate Consumed (g)', y: 'Sleep Length (hr)' },
    13: { x: 'Fat Consumed (g)', y: 'Sleep Length (hr)' },
    18: { x: 'Cycle Progress (%)', y: 'Basal Temperature (°C)' },
    19: { x: 'Cycle Progress (%)', y: 'Basal Temperature (°C)' },
}

/**
 * Get insight graph data.
 *
 * @param {Object} setsArray
 * @param {String} label
 * @return {Object} An object with attributes:
 *         {String[]} labels - values for x-axis
 *         {Object[]} datasets
 */
function _getChartData(setsArray, label) {
    if (setsArray.length < 1) return { labels: [], datasets: [] }
    let datasets = []
    setsArray.forEach((set, i) => {
        datasets.push({
            label: label,
            data: Object.values(set).map((value) => {
                return typeof value !== 'number' ? 0 : value
            }),
        })
    })
    return {
        labels: Object.keys(setsArray[0]),
        datasets: datasets,
    }
}

/**
 * Convert patient's insight sentence from second-person to third-person.
 *
 * @param {String} sentence
 * @param {String} name patient's name
 * @return {String} Insight sentence for practitioner
 */
function _parseInsightSentence(sentence = 'Not available', name) {
    let s = sentence
    s = s.replace(/were/g, 'was')
    s = s.replace(/ you /g, ` I `)
    s = s.replace(/ your /g, ` my `)
    s = s.replace(/Your/g, `My`)
    s = s.replace(/You/g, `I`)
    if (s.charAt(0) !== 'I') s = s.charAt(0).toLowerCase() + s.slice(1)
    return `As ${name}, ${s}`
}

/**
 * Get all types of insight graphs data and their corresponding insights sentences
 *
 * @param {Object} insightData - chartData, insightSentences
 * @return {Object[]} An array that contains insight graphs/charts data object, each object has attributes:
 *                 {String[]} labels - values for x-axis
 *                 {Object[]} datasets - values for y-axis
 *                 {String} title - title of this correlstion graph/chart
 *                 {String} sentence - insight sentence for this insight graph/chart
 *                 {Number} chartType
 */
function getInsightsData(insightData, firstName) {
    // console.log("Received insights: ", insightData)
    if (!insightData) return []
    const displayChartType = [0, 1, 2, 8, 9, 11, 12, 13, 18]
    const chartData = Object.values(insightData.chartData).filter((chart) =>
        displayChartType.includes(chart.chartType)
    )
    const insightSentencesArr = Object.values(insightData.insightSentences)
    const insightSentences = {}
    let result = []
    insightSentencesArr.forEach((obj) => {
        insightSentences[obj.chartID] = obj.content
    })
    chartData.forEach((chart) => {
        let title = chartTitles[chart.chartType.toString()] || 'Not available'
        let label = title.split(' and ')[1].replace(' Aggregate', '')
        let data = _getChartData(Object.values(chart.setDict), label)
        data.chartID = chart.chartID
        data.chartType = chart.chartType
        data.title = title
        data.chartAxesTitle = chartAxesTitle[chart.chartType.toString()]
        data.sentence = _parseInsightSentence(
            insightSentences[chart.chartID],
            firstName
        )
        data.chartType = chart.chartType
        data.recommended = true
        result.push(data)
    })
    return result
}

export default getInsightsData
