import { resolvePromises } from '../../helpers/promiseHelpers'

/**
 * Get an array of active patients firebase uid.
 *
 * @param {Object} patientMap Object with key = patient's firebase id and value = patient's information object
 * @return {String[]} An array of active patients' firebase uids string
 */
function _getActivePatientIds(patientMap) {
    const activeP = Object.values(patientMap).filter((p) => p.status !== 'past')
    return activeP.map((p) => p.id)
}

/**
 * Get Quickblox user information for patient with patientid = @param patientId
 * Reference: https://docs.quickblox.com/docs/js-users#retrieve-user-by-login
 *
 * @param {String} patientId Patient's firebase id
 * @return {Promise} Quickblox user object
 */
function _getQbUser(patientId) {
    const params = {
        login: patientId,
    }
    return new Promise((resolve, reject) => {
        QB.users.get(params, (err, res) => {
            if (err) reject(err.message || JSON.stringify(err))
            else {
                resolve(res)
            }
        })
    })
}

/**
 * Get a list of Quickblox user information for each patient in @param patientMap
 *
 * @param {Object} patientMap Object with key = patient's firebase id and value = patient's information object
 * @return {Object[]} An array of Quickblox user objects
 */
async function getQbPatients(patientMap) {
    const activePIds = _getActivePatientIds(patientMap)
    const promises = activePIds.map((id) => _getQbUser(id))
    const resolvedPatients = await resolvePromises(promises)
    return resolvedPatients.map((qbUser) => {
        return {
            id: qbUser.login,
            patient_qb_id: qbUser.id,
            first_name: patientMap[qbUser.login].basicInfo.firstName,
            img: patientMap[qbUser.login].basicInfo.img,
        }
    })
}

export default getQbPatients
