
import React, { useState } from 'react'
import clsx from 'clsx'
import {
    Typography,
    makeStyles,
    CardContent,
    CardActions,
    Button,
    Card,
    Tooltip,
} from '@material-ui/core'

import { WHITE } from '../../constants/colors'
import { accept_invite_clinic_as_practitioner } from '../../lib/Api/Practitioner/index'


const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: '408px',
        padding: '20px',
        border: 'solid 2px #000',
        boxShadow: 'none',
        margin: '20px 0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('430')]: {
            width: '320px'
        },
        [theme.breakpoints.down('375')]: {
            width: '280px'
        },
    },
    cardContent: {
        textAlign: 'center',
    },
    cardActions: {

    },
    button: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '32px',
        }
    },
    declineButton: {
        marginRight: '60px',
        background: WHITE,
    },
    bottomText: {
        textAlign: 'center',
        fontSize: '9px',
    }
}))

const InvitationCard = ({ key, clinicId, clinicName, message, setLoading, setNotification, setError }) => {
    const classes = useStyles()

    const handleAcceptInvite = () => {

        setLoading(true)
        accept_invite_clinic_as_practitioner(clinicId)
            .then(() => {
                setNotification('You have successfully accepted the invitation.')
                
                setTimeout(()=> {
                    window.location.reload();
                }, 3000)


            })
            .catch((err) => {
                setError('Error accepting the invitation, reload the page and please try again.')
                console.log('error accepting invite', err)
            })

       

    }

    return (
        <Card className={classes.card} key={key}>

            <CardContent className={classes.cardContent}>
                <Typography
                    variant="h6"
                    align="center"
                >
                    {/* {`${invite.practitioner.first_name} ${invite.practitioner.last_name}`} */}
                    The clinic '{clinicName}'
                </Typography>
                <Typography variant="h6" align="center">
                    has invited you to be a team member
                </Typography>


                {message && message?.length > 0 ? `Message: ${message}` : null}


            </CardContent>
            <CardActions className={classes.cardActions}>
                <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    className={clsx(classes.declineButton, classes.button)}
                    onClick={() => { }}
                >
                    Decline
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    disabled={false}
                    onClick={() => handleAcceptInvite()}

                >
                    Accept
                </Button>
            </CardActions>
            <Typography variant="body2" className={classes.bottomText}>
                <b>By accepting this invitation from the clinic, you acknowledge and agree that the clinic will have access to your patients and relevant account information. Additionally, the clinic will be able to monitor your programs and the sales of your courses. Furthermore, you will not be required to pay the individual subscription fee for coaches upon accessing this clinic. </b>

            </Typography>
        </Card>
    )
}

export default InvitationCard
