import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'
import { populateIndex } from '../../../helpers/generalHelpers'

/**
 * Add new general note to patient with @param patientId 's profile.
 *
 * @param {String} patientId Patient's Firebase id
 * @param {Object} note item
 * @return {Promise<any>} Updated note list
 */
const addNote = (patientId, note) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ accessToken }) => {
                const params = { note }
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
                axios
                    .put(
                        `${baseUrl}/patients/${patientId}/notes/new`,
                        params,
                        reqBody
                    )
                    .then((res) => resolve(populateIndex(res.data.notes)))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default addNote
