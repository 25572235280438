import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { CHAT_SERVICE } from '../../constants/routes'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FeedbackIcon from '@material-ui/icons/Feedback'
import IconButton from '@material-ui/core/IconButton'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import HelpIcon from '@material-ui/icons/Help'
import LinkIcon from '@material-ui/icons/Link'
import QuestionAnswerRoundedIcon from '@material-ui/icons/QuestionAnswerRounded'
import MessageIcon from '@material-ui/icons/Message'
import PhoneIcon from '@material-ui/icons/Phone'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'

const useStyles = makeStyles((theme) => ({
    extendedIcon: {
        marginRight: theme.spacing(2),
    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(5),
        right: theme.spacing(5),
        '& .MuiSpeedDial-actions': {
            paddingLeft: '100px',
        },
    },
    icon: {
        width: '100px',
        textAlign: 'center',
    },
}))

const actions = [
    {
        icon: (
            <IconButton
                href="https://floka.typeform.com/to/m8CGSZ"
                target="_blank"
            >
                <HelpIcon style={{ color: 'black' }} />
            </IconButton>
        ),
        name: 'Support Ticket',
    },
    {
        icon: (
            <IconButton
                href="https://floka.typeform.com/to/vqcN5d"
                target="_blank"
            >
                <LinkIcon style={{ color: 'black' }} />
            </IconButton>
        ),
        name: 'General Feedback',
    },
    {
        icon: (
            <IconButton
                href="https://foregoing-canoe-d1b.notion.site/Fl-ka-University-Practitioner-63e1068dfe174b95be2ec0c98720217a"
                target="_blank"
            >
                <QuestionAnswerRoundedIcon style={{ color: 'black' }} />
            </IconButton>
        ),
        name: 'Coach Tutorial',
    },
    {
        icon: (
            <IconButton href="https://m.me/flokalife" target="_blank">
                <MessageIcon style={{ color: 'black' }} />
            </IconButton>
        ),
        name: 'Chat',
    },
    {
        icon: (
            <IconButton
                href="tel:1-415-914-7694"
                target="_blank"
                rel="noopener noreferrer"
            >
                <PhoneIcon style={{ color: 'black' }} />
            </IconButton>
        ),
        name: '(415)-914-7694',
    },
    {
        icon: (
            <IconButton
                href="https://calendly.com/floka/support-session?month=2021-04"
                target="_blank"
            >
                <CalendarTodayIcon style={{ color: 'black' }} />
            </IconButton>
        ),
        name: 'Support Session',
    },
]

function FeedbackButton() {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const matchChatRoute = useRouteMatch(CHAT_SERVICE)

    const handleOpen = () => setOpen(true)

    const handleClose = () => setOpen(false)

    return (
        <SpeedDial
            ariaLabel="feedback-speeddial"
            className={classes.speedDial}
            icon={
                <React.Fragment>
                    <FeedbackIcon className={classes.extendedIcon} />
                    <Typography variant="body2">How can we help ?</Typography>
                </React.Fragment>
            }
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            hidden={matchChatRoute ? true : false}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={
                        <Typography variant="body2" className={classes.icon}>
                            {action.name}
                        </Typography>
                    }
                    tooltipOpen
                    onClick={handleClose}
                />
            ))}
        </SpeedDial>
    )
}

export default FeedbackButton
