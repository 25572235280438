import React , { useState } from 'react'
import { 
    Grid, 
    Box, 
    makeStyles, 
    Button, 
    Typography, 
    Dialog, 
    DialogActions, 
    DialogContentText, 
    DialogContent, 
    DialogTitle, 
    Slide, 
    IconButton } from '@material-ui/core'
import TutorialPaymentOnetime from '../../assets/TutorialPaymentOneTime.png'
import TutorialPaymentMembership from '../../assets/TutorialPaymentMembership.png'
import TutorialPaymentHybrid from '../../assets/TutorialPaymentHybrid.png'
import { ArrowBack, Close } from '@material-ui/icons'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme) => ({

    backIcon:{
        cursor:'pointer'
    },
    img:{
       height:'75px',
       objectFit:'cover'
    },
    modalTitle: {
        textAlign: 'center',
        padding:"18px 10px 18px 10px",
        margin: '5px 0px 15px 0px',
        backgroundColor:"#f9f6ff",
        borderRadius:'20px',
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        gap:'8px',
        [theme.breakpoints.down(850)]: {
            flexDirection:'column',
            gap:'15px',
        }
    },
    dialogTitle:{
        width:'100%'
    },
    box:{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"flex-end",
        [theme.breakpoints.down(500)]: {
            justifyContent:'center'
        },

    },
    dialogContent:{
       fontSize:'18px',
       height:'100%',
       textAlign:'center',
       padding:'0px 20px 0px 20px',
       marginBottom:'50px',
       marginTop:"20px",
       display:'flex',
       flexDirection:'column',
       justifyContent:'center',
       alignItems:'center',
       [theme.breakpoints.down(600)]: {
           padding:'0px 20px 0px 20px',
       }

    },
    dialogUpper:{
       width:'100%',
       textAlign:'center',
       color:"#6e6f71",
       [theme.breakpoints.down(1000)]: { 
           width:'80%'
       }
    }, 
    dialogLower:{
        display:'flex',  
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        flexWrap:'wrap',
        gap:"30px",
        marginTop:'30px',
        [theme.breakpoints.down(600)]: {
           
        }
    },
    container:{
        backgroundColor:"#eeeeee",
        borderRadius:"8px",
        height:'180px',
        width:'180px',
        padding: '10px',
        display:'flex',
        flexDirection:'column',
        gap:'20px',
        justifyContent:'center',
        alignItems:'center',
        fontSize:'16px',
    },
    subTitle:{
       fontWeight:"bold"
    },
    text:{
       color:"#6e6f71"
    },
    modalBox: {
        '& .MuiPaper-root': {
            width: '80%',
            maxWidth: '900px',
            borderRadius: '30px',
            padding: '10px 30px',
        }
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '5%',
        gap: '20px',
        [theme.breakpoints.down(1000)]: {
            flexDirection: 'column',
        },

    },
    leftButton: {
        border:'1px solid #000',
        width:'220px',
        backgroundColor: '#fff',
        color:"#000",
        borderRadius: '20px',
        '&:hover': {
            backgroundColor: '#fff',
        },

    },
    rightButton: {
        width:'220px',
        backgroundColor: '#000',
        color: '#fff',
        borderRadius: '20px',
        padding: '5px 20px 5px 20px',
        '&:hover': {
            backgroundColor: '#000',
        },
        "&:disabled": {
            backgroundColor: '#eeeeee',
        }
    },
    input:{
        width:'100%',
        borderRadius:'20px',
        border:'0.5px solid #b6b6b6',
        marginBottom:'20px',
        height:'40px',
        padding:'10px',
        "&:focus": {
            outline:"none"
        },
    }



}))

const Course = ({ onClose, open, handleNavigate }) => {
    

 
    const classes = useStyles()


    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            className={classes.modalBox}
        >
            <Typography variant="h4" paragraph className={classes.modalTitle}>
                <ArrowBack className={classes.backIcon} onClick={()=>handleNavigate('welcome')}/> 
                Awesome! Get ready for growth 🌲
                <Close className={classes.backIcon} onClick={onClose}/>
            </Typography>

            <div className={classes.dialogContent}>
                <div className={classes.dialogUpper}>
                    Our courses feature allows for the creation of either a 1-time payment course,
                    membership based course, and a 1:1 hybrid course!
                </div>

                <div className={classes.dialogLower}>
                    <div 
                        className={classes.container} 
                        onClick={()=>{}}
                    >
                        <img src={TutorialPaymentOnetime} className={classes.img}/>
                        
                        <div className={classes.text}>
                           1-time Payment
                        </div>
                    </div>

                    <div 
                        className={classes.container}  
                        onClick={()=>{}}
                    >
                        <img src={TutorialPaymentMembership} className={classes.img}/>
                        <div className={classes.text}>
                            Membership-Based
                        </div>
                    </div>

                    <div 
                        className={classes.container}  
                        onClick={()=>{}}
                    >
                        <img src={TutorialPaymentHybrid} className={classes.img}/>
                        <div className={classes.text}>
                            1:1 Hybrid Courses
                        </div>
                    </div>
                </div>
                
        
            </div>
            <DialogActions className={classes.buttonsContainer}>
                <Button 
                    className={classes.leftButton} 
                    onClick={()=>{
                        window.open('https://foregoing-canoe-d1b.notion.site/Fl-ka-Course-Partner-How-to-Launch-Your-Course-or-Membership-e647eb8fe2214e9498110df805f67d32', '_blank')
                    }}

                >
                    View tutorial on Course Creation
                </Button>

                <Button 
                    className={classes.leftButton} 
                    onClick={()=>{
                        window.open("https://calendly.com/floka/floka-pro-demo", '_blank')
                    }}
                >
                    Book Personalized Demo Call
                </Button>

                <Button 
                    className={classes.rightButton} 
                    disabled={false}
                    onClick={()=>{
                        window.open("https://vimeo.com/836668395", '_blank')
                    }}
                    
                >
                    Host your Course on Flöka
                </Button>
            </DialogActions>
        </Dialog>
    )


}

export default Course