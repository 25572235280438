import { getUser } from './config'

/**
 * Verify account password using @param oldPassword and update practitioner account password
 * in Cognito User Pool from @param oldPassword to @param newPassword
 *
 * @param {String} oldPassword - old account password
 * @param {String} newPassword - new account password
 * @return {Promise} Update success message
 */
const changePassword = (oldPassword, newPassword) => {
    const cognitoUser = getUser()

    if (cognitoUser) {
        return new Promise((resolve, reject) => {
            cognitoUser.getSession((err, _) => {
                if (err) {
                    reject(err.message || JSON.stringify(err))
                } else {
                    cognitoUser.changePassword(
                        oldPassword,
                        newPassword,
                        (err, result) => {
                            if (err) {
                                // console.log("Err: ", err.message);
                                reject(err.message || JSON.stringify(err))
                            } else {
                                // console.log("Res: ", result);
                                resolve(result)
                            }
                        }
                    )
                }
            })
        })
    } else {
        throw new Error('no cognitiveUser value')
    }
}

export default changePassword
