import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'

/**
 * Update the 'tax_config.tax_regions' list with @param updatedRegion element
 * for current signin practitioner.
 *
 * @param {Object} updatedRegion
 * @return {Promise<any>}
 */
const updateTaxRegions = (updatedRegion) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ id, accessToken }) => {
                const index = updatedRegion.index
                delete updatedRegion.index

                const params = { index, updatedRegion }
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }

                axios
                    .put(
                        `${baseUrl}/practitioner/${id}/tax-regions/update`,
                        params,
                        reqBody
                    )
                    .then((res) => resolve(res))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default updateTaxRegions
