import { getAuthSession } from '../Auth/index'

/**
 * Create a new Quickblox user.
 * Reference: https://docs.quickblox.com/docs/js-users#create-user
 */
async function createQbUser() {
    const { id, email } = await getAuthSession()
    const params = {
        login: id,
        password: id,
        email: email,
        user_tags: 'practitioner',
    }
    return new Promise((resolve, reject) => {
        QB.users.create(params, (err, res) => {
            if (err) {
                reject(err.message || JSON.stringify(err))
            } else {
                // console.log("Created user...");
                resolve(res)
            }
        })
    })
}

export default createQbUser
