import axios from 'axios'
import { populateIndex } from '../../../helpers/generalHelpers'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'

/**
 * Get a list of program items from program patient table in dynamoDB.
 *
 * @return {Promise<any>}
 */
const getPatientPrograms = (patientId) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ accessToken }) => {
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
                axios
                    .get(`${baseUrl}/patients/${patientId}/programs`, reqBody)
                    .then((res) => {
                        const schedules = res.data.schedules
                            ? res.data.schedules.map((schedule) =>
                                  populateIndex(schedule)
                              )
                            : []
                        const compliance = res.data.compliance.data
                            ? res.data.compliance.data
                            : []
                        const syncedSchedules = res.data.syncedSchedules
                            ? res.data.syncedSchedules
                            : []
                        const syncedModules = res.data.syncedModules
                            ? populateIndex(res.data.syncedModules)
                            : []
                        const syncedPrograms = res.data.syncedPrograms
                            ? res.data.syncedPrograms
                            : []
                        const data = {
                            programs: populateIndex(res.data.programs),
                            schedules,
                            compliance: compliance,
                            syncedModules: syncedModules,
                            syncedPrograms,
                            syncedSchedules,
                        }
                        resolve(data)
                    })
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default getPatientPrograms
