import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'

/**
 * Add new module and schedule in DynamoDb with @param newModule.
 *
 * @param {Object} newModule item
 * @param {Object} data
 * 						- {String} 	programId
 * 						- {String} 	scheduleId
 * @param {Object[]} newSchdule program schedule
 * @return {Promise<any>} Updated practitioner module and schedule
 */
const addModuleSchedule = (newModule, data, newSchedule) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ id, accessToken }) => {
                newSchedule.forEach((schedule) => delete schedule.index)
                const params = { newModule, keys: data, newSchedule }
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
                axios
                    .put(
                        `${baseUrl}/practitioner/${id}/module/new`,
                        params,
                        reqBody
                    )
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default addModuleSchedule
