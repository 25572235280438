/**
 * Get file URL.
 * Reference: https://docs.quickblox.com/docs/js-chat-advanced#attachments
 *
 * @param {String} fileUID
 * @return {String} file URL
 */
function getAttachmentUrl(fileUID) {
    return QB.content.privateUrl(fileUID)
}

export default getAttachmentUrl
