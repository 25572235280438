import React from 'react'
import PropTypes from 'prop-types'
import Alert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import Typography from '@material-ui/core/Typography'
import { NavLink } from 'react-router-dom'
import { PASSWORD_FORGET, PLANS } from '../constants/routes'

function ErrorSnackbar(props) {
    const { error, onClose } = props
    const textLinks = [
        'Update your plan to include more seats and to accept this request. Update it here at Manage Billing Dashboard',
        'Update your plan to include more seats and to view this client profile. Update it here at Manage Billing Dashboard',
        `The latest payment attempt for your subscription has failed. Please update your payment information in Account > Your Flöka Plan > Manage Billing Dashboard. Update it here.`,
        'Upgrade to Practitionerpreneur to accept this request. Upgrade it here.',
    ]

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={error !== ''}
            onClose={onClose}
            style={{ textAlign: 'center' }}
        >
            <Alert variant="filled" severity="error" onClose={onClose}>
                {error ==
                'It looks like you forgot your password. Reset it here.' ? (
                    <NavLink
                        to={PASSWORD_FORGET}
                        style={{ textDecoration: 'none', color: '#fff' }}
                    >
                        <Typography variant="body1">
                            <b>{error}</b>
                        </Typography>
                    </NavLink>
                ) : textLinks.includes(error) ? (
                    <NavLink
                        to={PLANS}
                        style={{ textDecoration: 'none', color: '#fff' }}
                    >
                        <Typography variant="body1">
                            <b>{error}</b>
                        </Typography>
                    </NavLink>
                ) : (
                    <Typography variant="body1">
                        <b>{error}</b>
                    </Typography>
                )}
            </Alert>
        </Snackbar>
    )
}

ErrorSnackbar.propTypes = {
    error: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default ErrorSnackbar
