import { update_profile } from '../Api/Practitioner'
import { getAuthSession } from '../Auth/index'
import { create_app_session, create_qb_user } from './index'

const createUpdateQbUser = () => {
    create_qb_user()
        .then((res) => {
            // console.log('createQbUser::create_qb_user::success');
            // set local storage qbId
            localStorage.setItem('qbId', res.id)
            // update profile with qbId
            update_profile('qb_id', res.id)
                .then((res) => {
                    // console.log('createQbUser::update_profile::success', res.data);
                    return res.data.qb_id
                })
                .catch((err) =>
                    console.log('createQbUser::update_profile::error -', err)
                )
        })
        .catch((err) =>
            console.log('createQbUser::create_qb_user::error - ', err)
        )
}

/**
 * Log practitioner into Quickblox account and connect to Quickblox chat server.
 * Reference: https://docs.quickblox.com/docs/js-authentication#log-in-user
 *
 * @param {String} qbId Practitioner Quickblox id pulled from DynamoDB
 * @return {Promise} A list of contacts
 */
async function qbLogin(qbId) {
    const { id } = await getAuthSession()
    return new Promise((resolve, reject) => {
        // Create app session
        create_app_session()
            .then((session) => {
                // Login user and create user session
                const params = { login: id, password: id }

                QB.login(params, (err, _) => {
                    if (err) {
                        // console.log('qbLogin::error -', err);
                        const newQbId = createUpdateQbUser()
                        const userCredentials = {
                            userId: parseInt(newQbId),
                            password: id,
                        }
                        // Connect to chat server
                        QB.chat.connect(userCredentials, (err, contactList) => {
                            if (err) {
                                reject(err.message || JSON.stringify(err))
                            } else {
                                // console.log("Connected to chat server!");
                                resolve(contactList)
                            }
                        })
                    } else {
                        // console.log('create_app_session::QB.login::success');
                        const userCredentials = {
                            userId: parseInt(qbId),
                            password: id,
                        }
                        QB.chat.connect(userCredentials, (err, contactList) => {
                            if (err) {
                                reject(err.message || JSON.stringify(err))
                            } else {
                                resolve(contactList)
                            }
                        })
                    }
                })
            })
            .catch((err) => reject(err.message || JSON.stringify(err)))
    })
}

export default qbLogin
