
import axios from 'axios'
import { getAuthSession } from '../Auth/index'
import { baseUrl } from '../Api/config'

/**
 * Check stripe checkout page for course sass.
 *
 * @param {String} practitioner practitioner's id
 * @return {Promise<any>}
 */
const getBranchData = (patientId, branch) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(async ({ accessToken, id }) => {
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
                await axios.get(
                    `${baseUrl}/patients/${patientId}/data/${branch}`, reqBody)
                    .then((res) =>  {
                        console.log('successfull')
                        resolve(res.data)
                    })
                    .catch((err) => {
                        
                        console.log('error fetch')
                        reject(JSON.stringify(err))
                    })
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default getBranchData
