/**
 * @description This file contains all promise-related helper functions.
 */

/**
 * Return and array of resolved values
 *
 * @param {Promise[]} promiseArr - An array of Promise objects
 * @return {Object[]}
 */
export const resolvePromises = async (promiseArr) => {
    return await Promise.all(promiseArr)
}
