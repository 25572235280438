

import axios from 'axios';
import { baseUrl } from '../Api/config';
import { getAuthSession } from '../Auth';
// const { projectAuth } = require('../Auth/firebase_config')

const getOuraSleepData = (patientId, fromDate, toDate) =>{

    return new Promise((resolve, reject)=>{
        getAuthSession()
            .then(async ({ accessToken, id }) => {
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
                const params={
                    patientID: patientId,
                    from_Date: fromDate,
                    to_Date: toDate
                }
                await axios.post(
                        `${baseUrl}/ouraPractitioner/sleep_chart`,
                        params,
                        reqBody
                    )
                    .then((res) =>  resolve(res.data))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`get_oura_sleep_error::error - ${JSON.stringify(err)}`)
            )
     })
}

export default getOuraSleepData