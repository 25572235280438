import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import { getUserPool, setCurrUserSession } from './config'
import { Mixpanel } from '../Analytics/Mixpanel'
import { update_profile } from '../Api/Practitioner/index'
import { toDateString } from '../../helpers/dateHelpers'

const signin = (username, password) => {
    const authenticationData = {
        Username: username,
        Password: password,
    }
    const authenticationDetails = new AuthenticationDetails(authenticationData)

    const userData = {
        Username: username,
        Pool: getUserPool(),
    }

    const cognitoUser = new CognitoUser(userData)

    return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                setCurrUserSession(cognitoUser)
                const today = new Date()
                const formatedTime = toDateString(today)
                const payload = cognitoUser.signInUserSession.idToken.payload
                const { sub, email } = payload
                // Record signed in practitioner information using Mixpanel
                try{
                    
                    Mixpanel.identify(sub)
                    Mixpanel.track('Successful Login', {
                        'Login At': formatedTime,
                    })
                    Mixpanel.people.set({
                        $email: email,
                    })
                    
                }catch(err){
                    
                    console.log(err)
                    
                }
                update_profile('lastLoginAt', formatedTime).then(() =>
                console.log('Saved')
                )
                resolve(result)
            },
            onFailure: function (err) {
                Mixpanel.track('Failed Login')
                reject(err.message || JSON.stringify(err))
            },
            newPasswordRequired: function (userAttributes, requiredAttributes) {
                setCurrUserSession(cognitoUser)
                reject({
                    code: 'PasswordResetRequiredException',
                    message: 'New Password Required',
                    newPasswordRequired: true,
                })
            },
        })
    })
}

export default signin
