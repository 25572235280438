import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'


/**
 * Get practitioner profile from DynamoDb for current signin practitioner.
 *
 * @return {Promise<any>}
 */
const updateInviteStatus = (patientId, field, value) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ id, accessToken }) => {
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
                const params={ 
                    patientId,
                    field, 
                    value
                }
                // console.log(accessToken)
                axios
                    .put(`${baseUrl}/practitioner/${id}/update-invite-status`,params, reqBody)
                    .then(async (res) => {
                        console.log('res data', res.data);
                        resolve(res.data)
                        return
                    })
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getInviteFromClient::error - ${JSON.stringify(err)}`)
            )
    })
}

export default updateInviteStatus