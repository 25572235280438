import { getUser } from './config'
import { Mixpanel } from '../Analytics/Mixpanel'
import { qb_logout } from '../Chat/index'

/**
 * Signout current login practitioner
 * Amazon Cognito bug: - the access token remains in local session storage even user has signed out
 *                     - AWS only stops providing refresh token to refresh the access token
 */
const signout = () => {
    const cognitoUser = getUser()

    if (cognitoUser) {
        cognitoUser.signOut()
        Mixpanel.reset() // Stop event tracking for this user after logout
        qb_logout()
        Promise.resolve()
    } else {
        throw new Error('no cognitiveUser value')
    }
}

export default signout
