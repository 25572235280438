import React , { useState } from 'react'
import { 
    Grid, 
    Box, 
    makeStyles, 
    Button, 
    Typography, 
    Dialog, 
    DialogActions, 
    DialogContentText, 
    DialogContent, 
    DialogTitle, 
    Slide, 
    IconButton } from '@material-ui/core'
import TutorialOneToOne from '../../assets/TutorialOneToOne.png'
import TutorialProgram from '../../assets/TutorialProgram.png'
import TutorialCourse from '../../assets/TutorialCourse.png'
import { Close } from '@material-ui/icons'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme) => ({

    img:{
       width:'150px'
    },
    modalTitle: {
        textAlign: 'center',
        padding:"15px 10px 15px 10px",
        margin: '5px 0px 15px 0px',
        backgroundColor:"#f9f6ff",
        borderRadius:'20px',
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        gap:'8px',
        [theme.breakpoints.down(800)]: {
            flexDirection:'column',
            gap:'15px'
        }
    },
    dialogTitle:{
        width:'100%'
    },
    box:{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"flex-end",
        [theme.breakpoints.down(500)]: {
            justifyContent:'center'
        },

    },
    dialogContent:{
       width:'100%',
       fontSize:'18px',
       height:'100%',
       textAlign:'center',
       padding:'0px 20px 0px 20px',
       marginBottom:'50px',
       marginTop:"20px",
       display:'flex',  
       flexDirection:'row',
       flexWrap:'wrap',
       justifyContent:'center',
       alignItems:'center',
       gap:"30px",
       [theme.breakpoints.down(800)]: {
           flexDirection:"column",
           height:'100%'
       }

    },
    container:{
        cursor:"pointer",
        boxShadow:"6px 6px 12px 6px rgba(0, 0, 0, 0.12)",
        borderRadius:"8px",
        height:'240px',
        width:'180px',
        padding: '10px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        fontSize:'12px',
        [theme.breakpoints.down(350)]: {
            width:'160px',
        }

    },
    subTitle:{
       fontWeight:"bold"
    },
    text:{
       color:"#6e6f71"
    },
    modalBox: {
        '& .MuiPaper-root': {
            width: '80%',
            maxWidth: '900px',
            borderRadius: '30px',
            padding: '10px 30px',
        }
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '5%',
        gap: '20px',
        [theme.breakpoints.down('900')]: {
            flexDirection: 'column',
            gap: '20px'
        },

    },
    leftButton: {
        border:'1px solid #000',
        width:'220px',
        backgroundColor: '#fff',
        color:"#000",
        borderRadius: '20px',
        padding: '5px 20px 5px 20x',
        '&:hover': {
            backgroundColor: '#fff',
        },

    },
    rightButton: {
        width:'220px',
        backgroundColor: '#000',
        color: '#fff',
        borderRadius: '20px',
        padding: '5px 20px 5px 20px',
        '&:hover': {
            backgroundColor: '#000',
        },
        "&:disabled": {
            backgroundColor: '#eeeeee',
        }
    },
    input:{
        width:'100%',
        borderRadius:'20px',
        border:'0.5px solid #b6b6b6',
        marginBottom:'20px',
        height:'40px',
        padding:'10px',
        "&:focus": {
            outline:"none"
        },
    },
    backIcon:{
        cursor:'pointer',
    }



}))

const Welcome = ({ onClose, open, name, handleNavigate }) => {
    

 
    const classes = useStyles()
    const [selectedContent, setSelectedContent] = useState("")


    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            className={classes.modalBox}
        >
            <Typography variant="h4" paragraph className={classes.modalTitle}>
               <div></div>
               <div>
                    Welcome, {name}
                    <Typography variant="h5">
                        How can we help you achieve your goal today?
                        
                    </Typography>
                </div>
                <Close className={classes.backIcon} onClick={onClose}/>
            </Typography>

            <div className={classes.dialogContent}>
                <div 
                    className={classes.container} 
                    onClick={()=>setSelectedContent('1:1')}
                    style={{ border:selectedContent === '1:1' ? '1px solid #000' : '1px solid #e4e4e4' }}
                >
                    <img src={TutorialOneToOne} className={classes.img} />
                    <div className={classes.subTitle}> 
                        I am a 1:1 Coach
                    </div>
                    <div className={classes.text}>
                        I would like to explore data-driven care with my 1:1 clients
                    </div>
                </div>

                <div 
                    className={classes.container}  
                    onClick={()=>setSelectedContent('course')}
                    style={{ border:selectedContent === 'course' ? '1px solid #000' : '1px solid #e4e4e4' }}
                >
                    <img src={TutorialProgram} className={classes.img} />
                    <div className={classes.subTitle}> 
                        I am a Course Creator
                    </div>
                    <div className={classes.text}>
                        I would love to launch a scalable course.
                    </div>
                </div>

                <div 
                    className={classes.container}  
                    onClick={()=>setSelectedContent('program')}
                    style={{ border:selectedContent === 'program' ? '1px solid #000' : '1px solid #e4e4e4' }}
                >
                    <img src={TutorialCourse} className={classes.img} />
                    <div className={classes.subTitle}> 
                        I am a Programs Creator
                    </div>
                    <div className={classes.text}>
                        I am here to build programs with tracking!
                    </div>
                </div>
                
        
            </div>
            <DialogActions className={classes.buttonsContainer}>
                <Button 
                    className={classes.leftButton} 
                    onClick={onClose}
                >
                    I’ll explore myself!
                </Button>

                <Button 
                    className={classes.rightButton} 
                    disabled={!selectedContent}
                    onClick={()=>{
                       handleNavigate(selectedContent)
                    }}
                >
                    Next
                </Button>
            </DialogActions>
        </Dialog>
    )


}

export default Welcome