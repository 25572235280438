

import axios from 'axios';
import { baseUrl } from '../config';
import { getAuthSession } from '../../Auth';
// const { projectAuth } = require('../Auth/firebase_config')

const changeSignInStatus = () =>{

    return new Promise((resolve, reject)=>{
        getAuthSession()
            .then(async ({ accessToken, id }) => {
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
                const params={
                    pracId:id
                }
                await axios.post(
                        `${baseUrl}/practitioner/update_login_status`,
                        params,
                        reqBody
                    )
                    .then((res) =>  resolve(res.data))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`get_oura_sleep_error::error - ${JSON.stringify(err)}`)
            )
     })
}

export default changeSignInStatus