import React , { useState } from 'react'
import { 
    Grid, 
    Box, 
    makeStyles, 
    Button, 
    Typography, 
    Dialog, 
    DialogActions, 
    DialogContentText, 
    DialogContent, 
    DialogTitle, 
    Slide, 
    IconButton } from '@material-ui/core'

import Send from '../../assets/send.svg'
import { Link as RouterLink } from 'react-router-dom'
import { update_invite_status } from '../../lib/Api/Practitioner';
import { REQUESTS } from '../../constants/routes'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme) => ({

    img:{
       width:'40px'
    },
    modalTitle: {
        textAlign: 'center',
        padding:"25px 10px 25px 10px",
        margin: '10px 0px 15px 0px',
        backgroundColor:"#f9f6ff",
        borderRadius:'20px',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        gap:'20px',
        [theme.breakpoints.down(600)]: {
            flexDirection:'column',
        }
    },
    dialogTitle:{
        width:'100%'
    },
    box:{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"flex-end",
        [theme.breakpoints.down(500)]: {
            justifyContent:'center'
        },

    },
    dialogContent:{
       fontSize:'18px',
       textAlign:'center',
       padding:'0px 150px 0px 150px',
       marginBottom:'50px',
       marginTop:"20px",
       [theme.breakpoints.down(600)]: {
           padding:'0px 20px 0px 20px',
       }

    },
    modalBox: {
        '& .MuiPaper-root': {
            width: '80%',
            maxWidth: '800px',
            borderRadius: '30px',
            padding: '10px 30px',
        }
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '5%',
        gap: '20px',
        [theme.breakpoints.down('900')]: {
            flexDirection: 'column',
            gap: '20px'
        },

    },
    leftButton: {
        border:'1px solid #000',
        width:'220px',
        backgroundColor: '#fff',
        color:"#000",
        borderRadius: '20px',
        padding: '5px 20px 5px 20x',
        '&:hover': {
            backgroundColor: '#fff',
        },

    },
    rightButton: {
        width:'220px',
        backgroundColor: '#000',
        color: '#fff',
        borderRadius: '20px',
        padding: '5px 20px 5px 20px',
        '&:hover': {
            backgroundColor: '#000',
        },
        "&:disabled": {
            backgroundColor: '#eeeeee',
        }
    },
    input:{
        width:'100%',
        borderRadius:'20px',
        border:'0.5px solid #b6b6b6',
        marginBottom:'20px',
        height:'40px',
        padding:'10px',
        "&:focus": {
            outline:"none"
        },
    }



}))

const NewInvitation = ({ open, onClose, practitionerId, invitationsNotified }) => {
    

 
    const classes = useStyles()


    return (
        <Dialog
            open={open}
            // onClose={onClose}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            className={classes.modalBox}
        >
            <Typography variant="h4" paragraph className={classes.modalTitle}>
                <img src={Send} className={classes.img}/> Accept Invite
            </Typography>
            <DialogContent className={classes.dialogContent}>
                Hello! You have a request to connect from a client. 
                Please visit the request page to
                accept this new invite.
        
            </DialogContent>
            <DialogActions className={classes.buttonsContainer}>
                <Button 
                    component={RouterLink}
                    className={classes.rightButton} 
                    to={REQUESTS}
                    onClick={async ()=>{
                        for(let i=0; i<invitationsNotified.length; i++){
                            let res = await update_invite_status(invitationsNotified[i].patient_id, 'is_notified_to_coach', true)
                            console.log('updated', res)
                        }
                        onClose()
                    }}
                >
                    Take me to Request Page
                </Button>

                <Button 
                    className={classes.rightButton} 
                    onClick={async ()=>{
                        for(let i=0; i<invitationsNotified.length; i++){
                            let res = await update_invite_status(invitationsNotified[i].patient_id, 'is_notified_to_coach', true)
                            console.log('updated', res)
                        }
                        onClose()
                    }}
                >
                    I will accept later
                </Button>
            </DialogActions>
        </Dialog>
    )


}

export default NewInvitation