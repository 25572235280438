import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'
import { populateIndex } from '../../../helpers/generalHelpers'

/**
 * Add new program and schedule in DynamoDb with @param newProgram.
 *
 * @param {Object} newProgram item
 * 						- {String} 	program_description
 * 						- {String} 	program_id
 * 						- {Number} 	program_length
 * 						- {String} 	program_title
 * @param {Object} data
 * 						- {String} 	programId
 * 						- {String} 	scheduleId
 * @param {Object[]} newSchdule program schedule
 * 						- {Bool} 	schedule_complete
 * 						- {Object[]} schedule_content
 * 						- {Number} 	schedule_day
 * 						- {String} 	schedule_description
 * 						- {Array} 	schedule_links
 * 						- {String} 	schedule_title
 * 						- {Number}	schedule_week
 * @return {Promise<any>} Updated practitioner program and schedule
 */
const addProgramSchedule = (newProgram, data, newSchedule, files) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ id, accessToken }) => {
                // Delete schedule.index
                newSchedule.forEach((schedule) => delete schedule.index)
                const params = { newProgram, data, newSchedule, files }
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
                axios
                    .put(
                        `${baseUrl}/practitioner/${id}/program/new`,
                        params,
                        reqBody
                    )
                    .then((res) => resolve(populateIndex(res.data.programs)))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default addProgramSchedule
