import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'
import decrypt from '../../Patient/decryptPatient'
import newDecrypt from '../../Patient/newDecrypt'

/**
 * Create a basic information dictionary for each patient in @param patients
 *
 * @param {Object[]} patients
 * @returns {Object} dict
 */
function createInfoDict(patients) {
    const dict = {}
    patients.forEach((patient) => {
        const { id, email, firstName, name, fullscript_id, status, signedURL } =
            patient
        if (!dict[id]) {
            let patientName = 'None'

            if (firstName) patientName = decrypt(firstName)
            else if (name) patientName = newDecrypt(name)

            dict[id] = {
                firstName: patientName,
                email,
                fullscript_id,
                status,
                signedURL,
            }
        }
    })
    return dict
}

/**
 * Get all patient name, email, fullscript_id and status for the currently signed in practitioner.
 * @return {Promise<any>}
 */
const getAllPatientBasicInfo = () => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ accessToken, id }) => {
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
                axios
                    .get(`${baseUrl}/practitioner/${id}/patients`, reqBody)
                    .then((res) => {
                        const patients = res.data
                        const newPatients = createInfoDict(patients)
                        resolve(newPatients)
                    })
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default getAllPatientBasicInfo
