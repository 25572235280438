import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom'
import {
    CssBaseline,
    Box,
    Drawer,
    Hidden,
    Link,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    AppBar,
    Toolbar,
    IconButton,
} from '@material-ui/core'
import { makeStyles, createTheme } from '@material-ui/core'
import { DARK_BLUE_MAGENTA, PALE_PINK, WHITE } from '../constants/colors'
import { signout } from '../lib/Auth/index'
import { Mixpanel } from '../lib/Analytics/Mixpanel'
import { toDateString } from '../helpers/dateHelpers'
import { exceptFirstLower, firstLetterUpper } from '../helpers/stringHelpers'
import { LANDING } from '../constants/routes'

import { ReactComponent as PatientsIcon } from '../assets/users.svg'
import { ReactComponent as ChatIcon } from '../assets/message-square.svg'
import { ReactComponent as FileIcon } from '../assets/file.svg'
import { ReactComponent as CalendarIcon } from '../assets/calendar.svg'
import { ReactComponent as InboxIcon } from '../assets/inbox.svg'
import { ReactComponent as PersonAddIcon } from '../assets/user-plus.svg'
import { ReactComponent as BusinessCenterIcon } from '../assets/briefcase.svg'
import { ReactComponent as UserIcon } from '../assets/user.svg'
import { ReactComponent as HelpCircleIcon } from '../assets/help-circle.svg'
import { ReactComponent as LogOutIcon } from '../assets/log-out.svg'
import { ReactComponent as MenuIcon } from '../assets/Hamburger-menu.svg'
import { ReactComponent as CoursesIcon } from '../assets/courses-icon.svg'
import { ReactComponent as LinkIcon } from '../assets/link.svg'
import { ReactComponent as BellIcon } from '../assets/bell.svg'
import { ReactComponent as FileText } from '../assets/file-text.svg'
import { ReactComponent as Clipboard } from '../assets/clipboard.svg'
import { ReactComponent as ClinicIcon } from '../assets/clinic-icon.svg'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import FlokaLogo from '../assets/FLÖKA.svg'
import blueBlob from '../assets/blueBlob.svg'

const drawerWidth = 260



const useStyles = makeStyles((theme) => ({

    drawer: {
        display: 'none',
        [theme.breakpoints.up('768')]: {
            display: 'block',
        },
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
        transitionDuration: '5s'
    },
    appBar: {
        display: 'none',
        [theme.breakpoints.down('768')]: {
            display: 'block',
            boxShadow: 'none',
        },
        marginLeft: drawerWidth,

    },
    drawerPaper: {
        width: drawerWidth,
        backgroundImage: 'linear-gradient(555deg, rgba(215,205,234,1) 0%, rgba(178,188,247,1) 100%)',
        overflowX: 'hidden',
        border: 'none',
        transitionDuration: '5s'


    },
    menuItems: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        gap: '25px',

    },
    link: {
        textDecoration: 'none',
    },
    listItemButton: {
        padding: '0',
    },
    listItemIcon: {
        minWidth: '0',
        marginRight: '18px',
        paddingLeft: '20px',
    },
    listItemText: {
        color: '#505050',
        fontSize: '20px',
        fontFamily: 'Value Serif Pro',
    },
    logoToolbar: {
        width: '95px',
    },
    logoSideBar: {
        width: '174px',
        margin: '26px 38px',
    },
    sidebarItems: {
        height: '100%',

    },
    signOut: {
        margin: 'auto 0 30px',
        textDecoration: 'none',
    },
    toolbar: {
        backgroundColor: '#CDCFFC', // was '#d7cdea'
        justifyContent: 'space-between',
    },
    pendingRequest: {
        display: 'flex',
        gap: '16px',
    },
    notificationIndicator: {
        width: '50px',
        height: '25px',
        borderRadius: '30px',
        padding: '10px',
        backgroundColor: 'red',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }



}))

function ResponsiveDrawerMenu(props) {

    const isLanding = window.location.href.split('/').includes('landing')
    const classes = useStyles()
    const { list, signOutApp, isFirstLogin } = props
    const [currentRoute, setCurrentRoute] = useState('/')
    const [open, setOpen] = useState(false)
    const today = new Date()

    let location = useLocation()

    const handleRouteUpdate = () => setCurrentRoute(location.pathname)

    useEffect(() => handleRouteUpdate())

    const handleSignOut = () => {
        signout()
        Mixpanel.track('Logout', {
            'Logout At': toDateString(today),
        })
        signOutApp()
    }

    const handlePageChange =
        (pageName = '') =>
            () => {
                Mixpanel.track(
                    `View ${firstLetterUpper(exceptFirstLower(pageName))} Page`,
                    {
                        'View At: ': toDateString(today),
                    }
                )
            }

    const handleDrawerToggle = () => {
        setOpen(!open)
    }

    const sideBarItems = (
        <List disablePadding className={classes.menuItems}>
            {list.map((item) => {
                let fontColor = matchPath(currentRoute, item.route)
                    ? DARK_BLUE_MAGENTA
                    : '#505050'


                //Clinic logic for path color
                if (currentRoute.startsWith('/clinic/')) {
                    fontColor = matchPath('/clinic/manager', item.route)
                        ? DARK_BLUE_MAGENTA
                        : '#505050'
                }



                return (
                    <RouterLink
                        to={item.route}
                        key={item.text}
                        className={classes.link}
                        onClick={() => { handlePageChange(); }}
                    >
                        <ListItem
                            button
                            key={item.text}
                            className={classes.listItemButton}
                        >
                            {item.text === 'My Clients' ? (
                                <ListItemIcon className={classes.listItemIcon}>
                                    <PatientsIcon />
                                </ListItemIcon>
                            )
                                : item.text === 'CHAT' ? (
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <ChatIcon />
                                    </ListItemIcon>
                                )
                                    : item.text === 'Files' ? (
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <FileIcon />
                                        </ListItemIcon>
                                    )
                                        : item.text === 'Notifications' ? (
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <BellIcon />
                                            </ListItemIcon>
                                        ) : item.text === 'PROGRAMS' ? (
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <CalendarIcon />
                                            </ListItemIcon>
                                        ) : item.text === 'CLINIC MANAGER' ? (
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <CalendarIcon />
                                            </ListItemIcon>
                                        ) : item.text === 'Public Links' ? (
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <LinkIcon />
                                            </ListItemIcon>
                                        )
                                            : item.text === 'Intake Forms' ? (
                                                <ListItemIcon className={classes.listItemIcon}>
                                                    <FileText />
                                                </ListItemIcon>
                                            )
                                                : item.text === 'Protocols' ? (
                                                    <ListItemIcon className={classes.listItemIcon}>
                                                        <Clipboard />
                                                    </ListItemIcon>
                                                )
                                                    : item.text === 'REQUESTS' ? (
                                                        <ListItemIcon className={classes.listItemIcon}>
                                                            <InboxIcon />
                                                        </ListItemIcon>
                                                    ) : item.text === 'ADD NEW CLIENT' ? (
                                                        <ListItemIcon className={classes.listItemIcon}>
                                                            <PersonAddIcon />
                                                        </ListItemIcon>
                                                    ) : item.text === 'CLIENTS LIST' ? (
                                                        <ListItemIcon className={classes.listItemIcon}>
                                                            <Clipboard />
                                                        </ListItemIcon>
                                                    ) : item.text === 'NEW PRACTITIONER' ? (
                                                        <ListItemIcon className={classes.listItemIcon}>
                                                            <PersonAddIcon />
                                                        </ListItemIcon>
                                                    ) : item.text === 'BUSINESS MANAGER' ? (
                                                        <ListItemIcon className={classes.listItemIcon}>
                                                            <BusinessCenterIcon />
                                                        </ListItemIcon>
                                                    ) : item.text === 'ACCOUNT' ? (
                                                        <ListItemIcon className={classes.listItemIcon}>
                                                            <UserIcon />
                                                        </ListItemIcon>
                                                    ) :
                                                        item.text === 'COURSES LIBRARY' ? (
                                                            <ListItemIcon className={classes.listItemIcon}>
                                                                <CoursesIcon />
                                                            </ListItemIcon>
                                                        ) :
                                                            item.text === 'CLINIC INVITE' ? (
                                                                <ListItemIcon className={classes.listItemIcon}>
                                                                    <ClinicIcon />
                                                                </ListItemIcon>
                                                            ) :
                                                                (
                                                                    <ListItemIcon
                                                                        className={classes.listItemIcon}
                                                                    ></ListItemIcon>
                                                                )}
                            <ListItemText
                                style={{ color: fontColor }}
                                disableTypography
                                primary={
                                    item.text === 'REQUESTS' && item.showDot ? (
                                        <Box className={classes.pendingRequest}>
                                            <b>Requests</b>
                                            <FiberManualRecordIcon fontSize="small" />
                                        </Box>
                                    ) :
                                        item.text === 'Notifications' && item.showDot ? (
                                            <Box className={classes.pendingRequest}>
                                                <b>Notifications</b>
                                                <FiberManualRecordIcon fontSize="small" />
                                            </Box>
                                        ) :
                                            (
                                                exceptFirstLower(item.text)
                                            )
                                }
                                className={classes.listItemText}
                            />
                        </ListItem>
                    </RouterLink>
                )
            })}
            <Link
                href="https://foregoing-canoe-d1b.notion.site/Fl-ka-University-Practitioner-63e1068dfe174b95be2ec0c98720217a"
                target="_blank"
                className={classes.link}
                underline="none"
            >
                <ListItem button className={classes.listItemButton}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <HelpCircleIcon />
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={'Tutorial'}
                        className={classes.listItemText}
                    />
                </ListItem>
            </Link>
            <RouterLink
                to="/"
                onClick={handleSignOut}
                className={classes.signOut}
            >
                <ListItem button className={classes.listItemButton}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <LogOutIcon />
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={'Sign Out'}
                        className={classes.listItemText}
                    />
                </ListItem>
            </RouterLink>
        </List>
    )

    if (isLanding) return <></>

    return (
        <>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        color="inherit"
                        aria-label="menu"
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon />
                    </IconButton>
                    <RouterLink to={LANDING}>
                        <img
                            src={FlokaLogo}
                            className={classes.logoToolbar}
                            alt="floka-logo"
                        />
                    </RouterLink>
                    <Box width="34px"></Box>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer}>
                <Hidden smUp implementation="css">
                    <Drawer
                        variant="temporary"
                        open={open}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,


                        }}
                        transitionDuration={{ appear: 750, enter: 750, exit: 750 }}
                        anchor='left'
                    >

                        <RouterLink to={LANDING} onClick={handleDrawerToggle}>
                            <img
                                src={FlokaLogo}
                                className={classes.logoSideBar}
                                alt="floka-logo"
                            />
                        </RouterLink>
                        <div className={classes.sidebarItems} onClick={handleDrawerToggle}>
                            {sideBarItems}
                        </div>
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                        onClick={handleRouteUpdate}

                    >
                        <RouterLink to={LANDING}>
                            <img
                                src={FlokaLogo}
                                className={classes.logoSideBar}
                                alt="floka-logo"
                            />
                        </RouterLink>
                        <div className={classes.sidebarItems}>

                            {sideBarItems}
                        </div>
                    </Drawer>
                </Hidden>
            </nav>
        </>
    )
}

ResponsiveDrawerMenu.propTypes = {
    list: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            route: PropTypes.string.isRequired,
        })
    ).isRequired,
    signOutApp: PropTypes.func.isRequired,
}

export default ResponsiveDrawerMenu
