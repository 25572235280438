import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'
import { populateIndex } from '../../../helpers/generalHelpers'

/**
 * Add index to data.
 *
 * @param {Object[]} data recommendation and notes
 * @return {Object[]} result
 */
function populateData(data) {
    let result = []
    if (!data) return result
    result = populateIndex(data)
    return result
}

/**
 * Get all recommendations and notes from current signin practitioner
 * for patient with @param patientId.
 *
 * @param {String} patientId Patient's Firebase id
 * @return {Promise<any>}
 */
const getRecNotes = (patientId) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ accessToken }) => {
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
                axios
                    .get(
                        `${baseUrl}/patients/${patientId}/recommendations`,
                        reqBody
                    )
                    .then((res) => {
                        const data = {
                            notes: populateData(res.data.notes),
                            recommendations: populateData(
                                res.data.recommendation
                            ),
                            files: populateData(res.data.files || []),
                            forms: populateData(res.data.intakeforms || []),
                        }
                        resolve(data)
                    })
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default getRecNotes
