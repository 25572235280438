import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'

/**
 * Update subscription record for currently signed-in practitioner.
 * 1. Event type
 * 	a. Updates subscription usage event for archiving/unarchiving a patient for current signin practitioner.
 * 2. Config type
 *  a. Updates subscription config record type
 *
 * @param {Object} data subscription data key and pair values
 * @param {String} key sort key to update record type
 * @return {Promise<any>}
 */
const updateSubscriptionEvent = (data, key) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ id, accessToken }) => {
                const params = { data, key }
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }

                axios
                    .put(
                        `${baseUrl}/practitioner/${id}/subscription/event/update`,
                        params,
                        reqBody
                    )
                    .then((res) => resolve(res))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default updateSubscriptionEvent
