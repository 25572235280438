import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'

/**
 * Add new draft sync program and modules to Program-Practitioner table in DynamoDb.
 *
 * @param {Object} newProgram
 * @param {Object} newSchdule
 * @return {Promise<any>} Updated program and schedule
 */
const addDraftSyncedProgram = (newSyncedModule) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ id, accessToken }) => {
                const params = { newSyncedModule }
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
                axios
                    .put(
                        `${baseUrl}/practitioner/${id}/program/draft/new`,
                        params,
                        reqBody
                    )
                    .then((res) => resolve(res))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default addDraftSyncedProgram
