/**
 * Create QuickBlox app session
 * Reference: https://docs.quickblox.com/docs/js-authentication#create-session
 *
 * @return {Promise} Quickblox app session
 */
function createAppSession() {
    return new Promise((resolve, reject) => {
        QB.createSession((err, session) => {
            if (err) {
                reject(err.message || JSON.stringify(err))
            } else {
                resolve(session)
            }
        })
    })
}

export default createAppSession
