import React, { useEffect, useState } from 'react'
import { Tabs, Box, Tab } from '@material-ui/core'
import {
    Link as RouterLink,
    Route,
    useLocation,
    useHistory,
} from 'react-router-dom'
import ErrorSnackbar from '../elements/ErrorSnackbar'
import Notification from '../elements/Notification'


import InvitationCard from '../components/ClinicInvitationCard/InvitationCard'
import LoadingSpinner from '../components/utils/LoadingSpinnerCSS'
import noInvitation from '../assets/noInvitation.png'
import { get_clinic_requests } from '../lib/Api/Practitioner/index'

import {
    Grid,
    Typography,
    makeStyles,
} from '@material-ui/core'

const tabLabels = ['Your Invitations']

const useStyles = makeStyles((theme) => ({
    noDataImg: {
        width: '50%',
        height: 'auto',
    },
    noImgContianer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    link: {
        textDecoration: 'none',
        color: "#fff",
        backgroundColor: "#000",
        padding: "10px 20px 10px 20px",
        borderRadius: "3px"
    }
}))

function ClinicInvitesPage(props) {

    const classes = useStyles();
    let location = useLocation()
    const history = useHistory()
    const [invites, setInvites] = useState([])
    const [notification, setNotification] = useState('')
    const [error, setError] = useState('')
    const [openInvoice, setOpenInvoice] = useState(false)
    const [loading, setLoading] = useState(false)



    useEffect(() => {
        setLoading(true)
        get_clinic_requests()
            .then((data) => {
                
                setInvites(data)
                setLoading(false)

            })
            .catch((err) => {
                setError('Error fetching requests, refresh the page.')
            })
    }, [])



    const closeNotification = () => setNotification('')

    const handleClose = () => setError('')




    return (
        <>
            {loading ? <LoadingSpinner text="Loading Invites ..." /> : <Grid container direction="column" alignItems="center" justifyContent='center'>

                {!invites || invites.length === 0 ? (
                    <div className={classes.noImgContianer}>
                        <img
                            className={classes.noDataImg}
                            src={noInvitation}
                            alt={'no invitation'}
                        />
                    </div>
                ) : (
                    invites.map((invite, i) => {
                        return (
                            <InvitationCard
                                key={i}
                                clinicId={invite.clinicId}
                                clinicName={invite.clinic_name}
                                message={invite.message}
                                setLoading={setLoading}
                                setNotification={setNotification}
                                setError={setError}
                            />
                        )
                    })
                )}

            </Grid>}
            <Notification text={notification} onClose={closeNotification} />
            <ErrorSnackbar error={error} onClose={handleClose} />
        </>
    )
}

export default ClinicInvitesPage
