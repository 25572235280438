import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'

/**
 * Remove an invitation item from DynamoDb.
 *
 * @param {Object} patient
 * @param {Boolean} shouldDecrement - True if invitation to be removed has paid recommended package and type = cancel
 * @return {Promise<any>}
 */
const removeInvRecord = (patient, shouldDecrement) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ id, accessToken }) => {
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                    data: {
                        invitationId: patient.invitationid,
                        shouldDecrement,
                    },
                }
                axios
                    .delete(
                        `${baseUrl}/practitioner/${id}/invitations/delete`,
                        reqBody
                    )
                    .then((res) => resolve(res))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default removeInvRecord
