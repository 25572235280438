import axios from 'axios'
import { populateIndex } from '../../../helpers/generalHelpers'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'

/**
 * Replace the element at position @param data.index from the 'programs' list
 * with @param newProgram for current signin practitioner.
 *
 * @param {Object} newProgram
 * @param {Object} data
 * @param {Object} newSchedule
 * @return {Promise<any>}
 */
const updateProgram = (newProgram, data, newSchedule, files) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ id, accessToken }) => {
                const params = { newProgram, data, newSchedule, files }
                const reqBody = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        Accept: 'application/pdf',
                        'Content-Type': 'application/json',
                    },
                }
                axios
                    .put(
                        `${baseUrl}/practitioner/${id}/program/update`,
                        params,
                        reqBody
                    )
                    .then((res) => {
                        const programs = res.data.programs
                            ? populateIndex(res.data.programs)
                            : []
                        const syncedPrograms = res.data.syncedPrograms
                            ? populateIndex(res.data.syncedPrograms)
                            : []
                        const files = res.data.files ? res.data.files : []
                        resolve({
                            programs: programs,
                            syncedPrograms: syncedPrograms,
                            files: files,
                        })
                    })
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default updateProgram
