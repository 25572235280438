/**
 * Upload video to cloudflare and return URL to save in dynamoDB
 *
 * @param {String} courseData parsed data to send to the backend
 */

import { getAuthSession } from '../../Auth/index'
import axios from 'axios'
import { baseUrl } from '../config'



async function updateCertificateUploadPractitioner(formData, newPractitionerID) {




    return new Promise(async (resolve, reject) => {


        const reqBody = {
            headers: {
                Accept: 'application/json',
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
            },
        }
        axios
            .post(
                `${baseUrl}/webhook/certificate/${newPractitionerID}`,
                formData,
                reqBody
            )
            .then((res) => resolve(res.data))
            .catch((err) => reject(JSON.stringify(err)))
    })
        

}

export default updateCertificateUploadPractitioner