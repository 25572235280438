export const ACCOUNT = '/account'
export const INTEGRATIONS = '/account/integrations'
export const PLANS = '/account/plans'
export const CLINIC_INFO = '/account/clinic-info'
export const CLINIC_RESOURCES_PRACT = '/account/clinic-resources'
export const BUSINESS = '/business'
// export const CHAT = '/chat'
export const CHAT_SERVICE = '/chat'
export const COURSE_BUSINESS ='/business/course'
export const GET_PAID = '/business/get_paid'
export const FULLSCRIPT = '/fullscript'
export const INVITATIONS = '/invitations'
export const INV_HISTORY = '/invitations/history'
export const LANDING = '/'
export const PASSWORD_FORGET = '/pw-forget'
export const PATIENTS = '/patients'
export const PROGRAMS = '/programs'
export const PATIENT_PROFILE = '/patients/:id'
export const PILLAR_DETAILED_VIEW = '/patients/:id/detail'
export const PATIENT_LAB_RESULT = '/patients/:id/lab-result'
export const REQUESTS = '/requests'
export const SIGN_UP = '/signup'
export const SIGN_UP_CLINIC = '/signup_clinic'
export const SIGN_UP_SUCCESSFUL = '/signup_successful'
export const SIGN_IN = '/signin'
export const SALES = '/business/sales'
export const TAX_CONFIG = '/business/tax-config'
export const LANDING_FORM ="/landingForm"
export const LIVE_PAGE = '/landingForm/live'
export const LANDING_PAGE = '/landing/:id'
export const GOOGLE_ANALYTICS='/landingForm/google-analytics'
export const LIBRARY = '/library'
export const NEW_COURSE = '/library/new'
export const EDIT_COURSE = '/library/edit/:id'
export const COURSES_SASS = '/sass/stripe/:checkout_id'
export const INTAKE_FORM = '/intake-form'
export const INTAKE_FORM_TABLE ='/intake-form/dash/table'
export const INTAKE_FORM_CREATE= '/intake-form/create'
export const INTAKE_FORM_EDIT= '/intake-form/edit/:id'
export const COURSES_SASS_SUCCESS = '/sass/stripe/:checkout_id/success'
export const COURSES_SASS_FAILED = '/sass/stripe/:checkout_id/failed'
export const NOTIFICATION = '/notification'
export const NOTIFICATION_SETTING = '/notification/setting'
export const PROTOCOL = '/protocol'
export const PROTOCOL_CREATE = '/protocol/create'
export const PROTOCOL_EDIT = '/protocol/edit/:id'
export const FILES = '/files'
export const CLINIC_MANAGER = '/clinic/manager'
export const CLINIC_PRACTIONERS_MANAGER = '/clinic/practitioners'
export const CLINIC_MANAGER_USER = '/clinic/manager/:id'
export const CLINIC_MANAGER_INVITE = '/clinic/invite'
export const CLINIC_MANAGER_INVITE_HISTORY = '/clinic/invite/history'
export const CLINIC_INVITATIONS = '/clinic/invitation'
export const CLINIC_CLIENT_LIST = '/clinic/clients'
export const CLINIC_RESOURCES = '/clinic/resources'

