/**
 * @description This file contains all date/time-related helper functions.
 */

import { validDate } from './validationHelpers'

/**
 * Get yesterday date.
 *
 * @param {String} d today's date string
 *                 Must be in the format of yyyy-mm-dd
 *                 eg. 2020-03-12
 * @return {Date}  Date object of yesterday
 */
export const _getYesterday = (d) => {
    d = stringToDate(d)
    d.setDate(d.getDate() - 1)
    return d
}

/**
 * Get next month date.
 *
 * @param {Date} d Date object
 * @return {Date}  Date object of next month
 */
export const getNextMonthDate = (d) => {
    if (d.getMonth() === 11) {
        d.setMonth(0)
        d.setYear(d.getFullYear() + 1)
        return d
    }
    d.setMonth(d.getMonth() + 1)
    return d
}

/**
 * Convert date object to a string in the UTC format.
 * Ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/UTC
 *
 * @param {Date} d  Date object
 * @return {String}  Date string in the UTC format
 */
export const toDateString = (d) => {
    return new Date(
        Date.UTC(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            d.getHours(),
            d.getMinutes(),
            d.getSeconds()
        )
    ).toUTCString()
}

/**
 * Get month name for @param d .
 *
 * @param {Date} d  Date object
 * @return {String}  Month name
 */
export const getMonthName = (d) => {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]
    return months[d.getMonth()]
}

/**
 * Convert string to a date object.
 *
 * @param {String} s date string to be converted
 *                 Must be in the format of yyyy-mm-dd
 *                 eg. 2020-03-12
 * @return {Date}  Date object of date string 's'
 */
export const stringToDate = (s) => {
    let bits = s.split('-')
    return new Date(bits[0], bits[1] - 1, bits[2])
}

export const secondsToDate = (date) => {
    var d = date ? new Date(date) : new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
}

/**
 * Convert date object to a string in the format of yyyy-mm-dd.
 *
 * @param {Date} d  Date object
 * @return {String}  Date string in the format of yyyy-mm-dd
 */
export const formatDate = (function () {
    function appendZero(n) {
        return n < 10 ? '0' + n : n
    }
    return function (d) {
        return (
            d.getFullYear() +
            '-' +
            appendZero(d.getMonth() + 1) +
            '-' +
            appendZero(d.getDate())
        )
    }
})()

/**
 * Convert date object to a string in the format of yyyy-mm-dd hh:mm AM/PM.
 *
 * @param {Date} d  Date object
 * @return {String}  Date string in the format of yyyy-mm-dd hh:mm AM/PM.
 */
export const formatDateTime = (function () {
    function appendZero(n) {
        return n < 10 ? '0' + n : n
    }
    return function (d) {
        let hrs = d.getHours()
        let ampm = hrs >= 12 ? 'PM' : 'AM'
        hrs = hrs % 12 === 0 ? 12 : hrs % 12
        return (
            d.getFullYear() +
            '-' +
            appendZero(d.getMonth() + 1) +
            '-' +
            appendZero(d.getDate()) +
            ' ' +
            appendZero(hrs) +
            ':' +
            appendZero(d.getMinutes()) +
            ' ' +
            ampm
        )
    }
})()

/**
 * Convert date object to a string in the format of (name of month dd, yyyy).
 * eg. May 03, 2020
 *
 * @param {Date} d  Date object
 * @return {String}  Date string in the format of (name of month dd, yyyy)
 */
export const formatTextMonthDate = (function () {
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ]
    function appendZero(n) {
        return n < 10 ? '0' + n : n
    }
    return function (d) {
        return `${months[d.getMonth()]} ${appendZero(
            d.getDate()
        )}, ${d.getFullYear()}`
    }
})()

/**
 * Get dates of the last 'length' days starting from d.
 *
 * @param {String} d  Date string
 * @param {Integer} length Number of previous days strting from d
 * @return {Object[]}  An array of object that holds day and dates in the last 'length' days tsrting from d
 *                  {  date: date string (yyyy-mm-dd),
 *                     day: day name of date (eg. "MON")
 */
export const getLastWeekDates = (d, length) => {
    const days = [
        'SUNDAY',
        'MONDAY',
        'TUESDAY',
        'WEDNESDAY',
        'THURSDAY',
        'FRIDAY',
        'SATURDAY',
    ]
    if (!validDate(d)) return null
    else {
        let dates = [
            {
                date: d,
                day: 'TODAY',
            },
        ]
        for (let i = 0; i < length - 1; i++) {
            let ytd = _getYesterday(dates[0].date)
            dates.unshift({
                date: formatDate(ytd),
                day: days[stringToDate(formatDate(ytd)).getDay()],
            })
        }
        return dates
    }
}

/**
 * Calculate the difference between two dates
 *
 * @param {String} label of starting cycle date
 * @param {String} periodDate of next cycle date
 * @return {Number}  diffDays
 */
export const diffDays = (start, end) => {
    let date1 = new Date(start)
    let date2 = new Date(end)
    const diffTime = Math.abs(date2 - date1)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return diffDays
}

/**
 * Convert 24-hour time, HH:MM:SS, to 12-hour time with AM/PM
 *
 * @param {String} label in HH:MM:SS date format
 * @return {String} time
 */
export const tConvert = (time) => {
    // Check correct time format and split into components
    time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]

    if (time.length > 1) {
        // If time format correct
        time = time.slice(1) // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM' // Set AM/PM
        time[0] = +time[0] % 12 || 12 // Adjust hours
    }
    return time.join('') // return adjusted time or original string
}

export const numberToMonth = {
    "1": "Jan",
    "01": "Jan",
    "2": "Feb",
    "02": "Feb",
    "3": "Mar",
    "03": "Mar",
    "4": "April",
    "04": "April",
    "5": "May",
    "05": "May",
    "6": "June",
    "06": "June",
    "7": "July",
    "07": "July",
    "8": "Aug",
    "08": "Aug",
    "9": "Sept",
    "09": "Sept",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec"
}
/**
 * Convert a string date , to Written Time: January 15th, 2022
 *
 * @param {String} label in YYYY-MM-DD Format
 * @return {String} time
 */

export const stringDateConverter = (date) => {

    const dateArr = date.replace(/-0+/g, '-').split('-');
    const year = dateArr[0]
    const month = numberToMonth[dateArr[1]];
    const day = dateArr[2];
    const superscriptOridnal =
        day === "1" ? "st" :
            day === "2" ? "nd" :
                day === "3" ? "rd" :
                    "th";


    return `${month} ${day}${superscriptOridnal}, ${year}`

}

//  Calculate numbers of range (years || months ||days) between two JavaScript Object

const treatAsUTC = (date) => {
    let result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
}

export const daysBetween = (startDate, endDate, range) => {
    let millisecondsPerDay = 24 * 60 * 60 * 1000;
    let daysPerMonth = millisecondsPerDay * 30;
    let daysPerYear = millisecondsPerDay * 365;

    switch (range) {
        case 'year':
            return (Math.abs((treatAsUTC(endDate) - treatAsUTC(startDate)) / daysPerYear)).toFixed(0);
        case 'month':
            return (Math.abs((treatAsUTC(endDate) - treatAsUTC(startDate)) / daysPerMonth)).toFixed(0);
        case 'day':
            return (Math.abs((treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay)).toFixed(0);
        default:
            return (Math.abs((treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay)).toFixed(0);
    }
}

export const chatServiceHelper = (date) => {
    const inputDate = new Date(date);

    // Get the current date and time
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - inputDate;

    // Calculate the time difference in minutes
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));

    // Calculate the time difference in hours
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

    // Calculate the time difference in days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    // Format the output based on conditions
    if (minutesDifference < 60) {
        // Less than an hour ago
        return (`${minutesDifference} min`);
    } else if (hoursDifference < 24) {
        // Less than a day ago
        return (`${hoursDifference} h`);
    } else {
        // More than a day ago
        return (`${daysDifference} day`);
    }
}

export const chatServiceMessageHelper = (date) => {

    // Convert the input date string to a Date object
    const inputDate = new Date(date);

    // Get the hours and minutes from the input date
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();

    // Format the output as "hh'h'mm"
    const formattedOutput = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

    return(formattedOutput)
}