/**
 * Transform @param completedObj into data structure for PolarAreaChart
 *
 * @param {Object} completedObj
 * @returns {Object}
 */
function analyzeRecProgress(completedObj) {
    const isCompleted = Object.values(completedObj)
    const totalNoOfDays = isCompleted.length
    const completedDays = isCompleted.filter((val) => val === true).length
    const completedPercentage = ((completedDays / totalNoOfDays) * 100).toFixed(
        2
    )
    return {
        datasets: [{ data: [100 - completedPercentage, completedPercentage] }],
        activities: ['Incomplete', 'Complete'],
        totalNoOfDays,
        completedDays,
    }
}

export default analyzeRecProgress
