import { CognitoUserAttribute } from 'amazon-cognito-identity-js'
import { getUserPool } from './config'

/**
 * Sign up practitioner in Cognito User Pool with @param credentials
 * @param {Object} credentials - Practitioner signup credentials
 *                             - Keys:
 *                                  email,
 *                                  firstName,
 *                                  lastName,
 *                                  clinicName,
 *                                  specialty,
 *                                  promoCode
 * @return {Promise}
 */
const signup = (credentials) => {
    const userPool = getUserPool()



    const attributeList = [
        new CognitoUserAttribute({
            Name: 'email',
            Value: credentials.email,
        }),
        new CognitoUserAttribute({
            Name: 'given_name',
            Value: credentials.firstName.split(' ')[0] ? credentials.firstName.split(' ')[0] : "",
        }),
        new CognitoUserAttribute({
            Name: 'family_name',
            Value: credentials.firstName.split(' ')[1] ? credentials.firstName.split(' ')[1] : "-familyName-",
        }),
        new CognitoUserAttribute({
            Name: 'name',
            Value: credentials.clinicName,
        }),
        new CognitoUserAttribute({
            Name: 'address',
            Value: '', // This must be kept as 'address' is set as required field in Cognito User Pool
        }),
        new CognitoUserAttribute({
            Name: 'custom:specialty',
            Value: credentials.specialty,
        }),
        new CognitoUserAttribute({
            Name: 'custom:promo_code',
            Value: credentials.promoCode ? credentials.promoCode : "none" ,
        }),
        new CognitoUserAttribute({
            Name: 'custom:isNewsLetter',
            Value: credentials.isNewsletterSubscribed ? 'Yes':'No',
        }),
        new CognitoUserAttribute({
            Name: 'custom:phone',
            Value: credentials.phone,
        }),
        new CognitoUserAttribute({
            Name:'custom:birthdate',
            Value: credentials.birthdate,
        }),
        new CognitoUserAttribute({
            Name:'custom:coachType',
            Value: credentials.coachType,
        }),
        new CognitoUserAttribute({
            Name:'custom:link',
            Value: credentials.link,
        }),
        new CognitoUserAttribute({
            Name:'custom:clinic',
            Value: credentials.clinic ? 'yes' : 'none',
        })


    ]

    if(credentials.clinicId){
        attributeList.push(new CognitoUserAttribute({
            Name: 'custom:clinicId',
            Value: credentials.clinicId,
        }))
    }

    
    console.log('------credentials--------')
    console.log(credentials)

    return new Promise((resolve, reject) => {
        userPool.signUp(
            credentials.email,
            credentials.password,
            attributeList,
            null,
            (err, result) => {
                if (err) {
                    console.log(err)
                    reject(err.message || JSON.stringify(err))
                } else {
                    console.log('ACCOUNT CREATED')
                    console.log(result)
                    resolve(result)
                }
            }
        )
    })
}

export default signup
