import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'
import { populateIndex } from '../../../helpers/generalHelpers'

/**
 * Remove one or more notes from patient with @param patientId 's profile.
 *
 * @param {String} patientId Patient's Firebase id
 * @param {Number[]} indices An array of indices of note to be removed from the note list
 * @param {string} label notes
 * @return {Promise<any>} Updated note list
 */
const removeFiles = (patientId, indices, label) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ accessToken }) => {
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                    data: { indices, label },
                }
                // console.log('req body', reqBody);
                axios
                    .delete(
                        `${baseUrl}/patients/${patientId}/files/delete`,
                        reqBody
                    )
                    .then((res) => resolve(populateIndex(res.data.files)))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default removeFiles