import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'

/**
 * Update the value of @param prop attribute to @param value in DynamoDb
 * for current signin practitioner.
 *
 * @param {String} prop
 * @param {*} value
 * @return {Promise<any>}
 */
const updateProfile = (prop, value) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ id, accessToken }) => {
                const params = {
                    attribute: prop,
                    value: value,
                }
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }

                axios
                    .put(
                        `${baseUrl}/practitioner/${id}/update`,
                        params,
                        reqBody
                    )
                    .then((res) => resolve(res))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default updateProfile
