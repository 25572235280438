import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'
import { populateIndex } from '../../../helpers/generalHelpers'

/**
 * Add index to data.
 *
 * @param {Object[]} data recommendation and notes
 * @return {Object[]} result
 */
function populateData(data) {
    let result = []
    if (!data) return result
    result = populateIndex(data)
    return result
}

/**
 * Add supplement item to patient's supplement list.
 * for patient with @param patientId.
 *
 * @param {String} patientId Patient's Firebase id
 * @return {Promise<any>}
 */
const addUserSupplement = (patientId, imageUrl,name, description) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ accessToken }) => {
                const params = {imageUrl, name, description }
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
                axios
                    .post(
                        `${baseUrl}/patients/${patientId}/supplement/update`,
                        params,
                        reqBody
                    )
                    .then((res) => {
                        console.log('res',res)
                        resolve(res.data)
                    })
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default addUserSupplement