import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'
import { convertPrice } from '../../../helpers/generalHelpers'
import { get_patients } from './index'
const qs = require('qs')

/**
 * Get all attributes listed in the @param attributes array from practitioner model.
 *
 * @param {String[]} attributes - An array of attribute names
 * @return {Promise<any>}
 */
const getAttributes = (attributes) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ id, accessToken }) => {
                const reqBody = {
                    params: { attributes },
                    paramsSerializer: (params) => {
                        return qs.stringify(params)
                    },
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
                axios
                    .get(
                        `${baseUrl}/practitioner/${id}/only-attributes`,
                        reqBody
                    )
                    .then(async (res) => {
                        try {
                            let data = res.data
                            if (attributes.includes('patients')) {
                                const users = await get_patients(
                                    res.data.patients
                                )
                                data.patients = users
                            }
                            if (attributes.includes('subscription_packages')) {
                                data.subscription_packages = convertPrice(
                                    res.data.subscription_packages
                                )
                            }
                            resolve(data)
                            return
                        } catch (err) {
                            reject(JSON.stringify(err))
                        }
                    })
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default getAttributes
