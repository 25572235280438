import { CognitoUserPool } from 'amazon-cognito-identity-js'

let userPool, currentUser

/**
 * Initialize Cognito User Pool with User Pool ID and Client App ID.
 * Then, set the global variable 'userPool' to the initialize CognitoUserPool object.
 */
const initAuth = () => {
    userPool = new CognitoUserPool({
        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    })
}

export const getUserPool = () => {
    return userPool
}

export const getUser = () => {
    return userPool.getCurrentUser()
}

export const setCurrUserSession = (user) => {
    currentUser = user
}

export const getCurrUserSession = () => {
    return currentUser
}

/** Initialize Cognito User Pool object */
initAuth()
