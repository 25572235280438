import axios from 'axios'
import { populateIndex } from '../../../helpers/generalHelpers'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'

/**
 * Replace the element at position @param data.index from the 'programs.modules' list
 * with @param newModule for current signin practitioner.
 *
 * @param {Object} newModule
 * @param {Object} data
 * @param {Object} newSchedule
 * @return {Promise<any>}
 */
const updateModule = (newModule, data, newSchedule) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ id, accessToken }) => {
                const params = { newModule, data, newSchedule }
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }

                axios
                    .put(
                        `${baseUrl}/practitioner/${id}/module/update`,
                        params,
                        reqBody
                    )
                    .then((res) => {
                        const modules = res.data.modules ? res.data.modules : []
                        const programs = res.data.programs
                            ? res.data.programs
                            : []
                        const draftModules = res.data.draftModules
                            ? res.data.draftModules
                            : []
                        // console.log('moduels', modules);
                        // console.log('programs', programs);
                        // console.log('res data', res.data)
                        modules.forEach((mod) => {
                            programs.some((pro) => {
                                if (pro.program_id === mod.link_to) {
                                    let linkToModule = {}
                                    linkToModule['link_to'] = mod.program_id
                                    linkToModule['link_to_title'] =
                                        mod.program_title
                                    linkToModule['link_to_phase'] =
                                        mod.program_phase
                                    if (!pro.link_to) pro.link_to = []
                                    pro.link_to.push(linkToModule)
                                }
                            })
                        })
                        const result = {
                            programs: populateIndex(programs),
                            modules: populateIndex(modules),
                            draftModules: populateIndex(draftModules),
                        }
                        // console.log('result', result);
                        resolve(result)
                    })
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default updateModule
