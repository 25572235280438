/* QuickBlox configuration object */
const QB_CONFIG = {
    endpoints: {
        api: 'https://api.quickblox.com', // set custom API endpoint
        chat: 'chat.quickblox.com', // set custom Chat endpoint
    },
    chatProtocol: {
        active: 2, // set 1 to use BOSH, set 2 to use WebSockets (default)
    },
    streamManagement: {
        enable: true,
    },
}

/**
 * Initialize global QuickBlox object
 * QB is available everywhere within this project
 * Reference: https://docs.quickblox.com/docs/js-setup#initialize-quickblox-sdk
 */
QB.init(
    process.env.REACT_APP_QB_APP_ID,
    process.env.REACT_APP_QB_AUTH_KEY,
    process.env.REACT_APP_QB_AUTH_SECRET,
    process.env.REACT_APP_QB_ACCOUNT_KEY,
    QB_CONFIG
)

QB.chat.onDisconnectedListener = onDisconnectedListener
QB.chat.onReconnectListener = onReconnectListener

function onReconnectListener() {
    console.log('QB::onReconnect')
}

function onDisconnectedListener() {
    console.log('QB::onDisconnected')
}

/**
 * @description Export all functions contains within the Chat folder in this file.
 */

import create_app_session from './createAppSession'
import create_dialog from './createDialog'
import create_qb_user from './createQbUser'
import delete_qb_user from './deleteQbUser'
import get_attachment_url from './getAttachmentUrl'
import get_chat_history from './getChatHistory'
import get_dialog_list from './getDialogList'
import get_qb_patients from './getQbPatients'
import qb_login from './qbLogin'
import qb_logout from './qbLogout'
import receive_message from './receiveMessage'
import send_attachment from './sendAttachment'
import send_message from './sendMessage'

export {
    create_app_session,
    create_dialog,
    create_qb_user,
    delete_qb_user,
    get_attachment_url,
    get_chat_history,
    get_dialog_list,
    get_qb_patients,
    qb_login,
    qb_logout,
    receive_message,
    send_attachment,
    send_message,
}
