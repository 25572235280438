import React from 'react'
import { MAX_PATIENT_COUNT } from '../constants/plans'

const EnablePlanContext = React.createContext({
    subsPlanId: '',
    isFull: false,
    remainingSeats: MAX_PATIENT_COUNT,
})

export default EnablePlanContext
