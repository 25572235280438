import { getUser } from './config'

const getSession = () => {
    const cognitoUser = getUser()

    if (cognitoUser) {
        return new Promise((resolve, reject) => {
            cognitoUser.getSession((err, session) => {
                if (err) {
                    reject(err.message || JSON.stringify(err))
                } else {
                    // console.log('Session: ', session);
                    const id = session.accessToken.payload.sub
                    resolve({
                        id: id,
                        accessToken: session.accessToken.jwtToken,
                        email: session.idToken.payload.email,
                    })
                }
            })
        })
    } else {
        throw new Error('no cognitiveUser value')
    }
}

export default getSession
